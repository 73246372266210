// refund.tsx
import React from 'react';
import { Container, ThemeProvider, createTheme, Box, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Refund from './Refund'; // Import Refund component
import Terms from './Terms'; // Import Refund component
import Privacy from './Privacy'; // Import Refund component
//import Shipping from './Shipping'; // Import Refund component

const theme = createTheme({

  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: false,
        sx: {
          width: {
            xs: '90%',
            sm: '50%',
            md: '50%',
          },
          textAlign: 'center',
          py: '20px',
        },
      },
    },
  },

  typography: {
    fontFamily: `'Poppins', sans-serif`,
  },
});

const ShippingPage: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>

      <Grid container direction="column" alignItems="center">
        {/* Red Section */}
        <Grid size={12} style={{ backgroundColor: 'black', width: '100%' }}>
          <Container>
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/Images/landingPage25.png`}
              alt="Life-Changing Bonuses"
              sx={{ width: { xs: '30%', sm: '20%', md: '20%' }, height: 'auto' }}
            />
            <Typography variant="body1" color="white" textAlign="center" sx={{
              fontSize: {
                xs: '1.4rem',
                sm: '1.5rem',
                md: '1.5rem'
              }
            }}>
              <p>
                SHIPPING POLICY
              </p>
            </Typography>
          </Container>
        </Grid>

        {/* Blue Section */}
        <Grid size={12} style={{ backgroundColor: 'white', width: '100%' }}>
          <Container>
            <Typography
              variant="body1"
              color="black"
              textAlign="left"
              sx={{
                fontSize: {
                  xs: '1rem',
                  sm: '1.2rem',
                  md: '1.2rem'
                }
              }}
            >

              <strong>1. Introduction</strong>

              <p>
                At Space Infinite, we understand the importance of timely and reliable delivery. This Shipping and Delivery Policy outlines how we deliver our online course content to our customers.
              </p>

              <strong>2. Electronic Delivery</strong>

              <p>
                2.1. Mode of Delivery: The Your Abundance Blueprint Report will be delivered electronically via email. No physical products will be shipped.
              </p>

              <p>
                2.2. Access: Upon successful completion of a purchase, customers will receive an email containing access link for the Report within 4 days from the date of Purchase. (6 hours in case of expedite delivery option selected while order)
              </p>

              <strong>3. Delivery Timing</strong>

              <p>
                3.1. 4 Days Delivery: Your Abundance Blueprint Report is delivered via email with in 4 days of your purchase.
              </p>

              <p>
                3.2. Delays: In rare instances, there might be a delay due to technical issues. If you don't receive your course within 4 Days, please contact our support team.
              </p>

              <strong>4. Incorrect Email Address</strong>

              <p>
                4.1. If you've provided an incorrect email address during purchase, please notify us as soon as possible. Once the issue is rectified, we'll resend the Your Abundance Report to the correct email address.
              </p>

              <strong>5. System Requirements</strong>

              <p>
                5.1. Ensure that your system meets any stated requirements for optimal course viewing. While our Report is designed to be compatible with most devices and browsers, we cannot guarantee that every course will work on all systems.
              </p>

              <strong>6. Resending Your Abundance Blueprint</strong>

              <p>
                6.1. If you accidentally delete your access email or cannot find it, contact our support, and we'll resend it to you.
              </p>

              <strong>7. Changes to this Policy</strong>

              <p>
                7.1. Destiny Decorderr reserves the right to modify this Shipping and Delivery Policy as needed.
              </p>

              <p>
                7.2. Any updates will be posted on this page, and we encourage users to review it regularly.
              </p>

              <strong>8. Contact Us</strong>

              <p>
                For inquiries about delivery, technical support, or other concerns:
              </p>

              <p>
                Email:{' '}
                <Link
                  to={""}
                  rel="noopener noreferrer"
                  style={{
                    color: '#178bf6', textDecoration: 'none',
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                  onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
                >
                  ask@rajhkumarsingh.com
                </Link>

              </p>

            </Typography>
          </Container>
        </Grid>

        {/* Green Section */}
        <Grid size={12} style={{ backgroundColor: 'black', width: '100%' }}>
          <Container sx={{ width: { xs: '90%', sm: '35%', md: '35%' } }}>

            <Box display="flex" justifyContent="center">
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/Images/landingPage1.png`}
                alt="Life-Changing Bonuses"
                sx={{ width: '60%', height: 'auto' }}
              />
            </Box>

            <Box display="flex" justifyContent="center">
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/Images/down2.png`}
                alt="Life-Changing Bonuses"
                sx={{ width: '20%', height: 'auto', marginTop: '10px' }}
              />
            </Box>

            <Button
              variant="contained"
              sx={{
                width: '100%',
                p: '10px',
                m: '10px',
                fontSize: { xs: '1rem', sm: '1.2rem', md: '1.2rem' },
                fontWeight: 'bold',
                backgroundColor: '#f8d371',
                color: 'black',
                borderRadius: '50px',
                border: '3px solid white',
                textTransform: 'none',
                '&:hover': { backgroundColor: '#d4ac0d' },
              }}
            >
              Order Your Report Now!
            </Button>

            <Typography variant="body1" color="white" textAlign="center" sx={{ fontSize: '1.1rem', marginTop: '30px' }}>
              <p>
                <span style={{ fontWeight: 'bold' }}>SPACE INFINITE</span>
                <br />
                Tiara C-403, Lodha Upper Thane
                <br />
                Anjur - Mankoli Road, Thane 421302
                <br />
                📲 +91 7888744481
                <br />
                📧 vastu@rajhkumarsingh.com
              </p>

              {/* Footer Links */}
              <p style={{ marginTop: '50px', fontSize: '0.9rem' }}>
                <Link to="/Refund" style={{ color: 'white', textDecoration: 'none' }}
                  onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                  onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
                >
                  &nbsp;Refund&nbsp;</Link>|
                <Link to="/Terms" style={{ color: 'white', textDecoration: 'none' }}
                  onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                  onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
                >
                  &nbsp;Terms&nbsp;</Link>|
                <Link to="/Privacy" style={{ color: 'white', textDecoration: 'none' }}
                  onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                  onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
                >
                  &nbsp;Privacy Policy&nbsp;</Link>|
                <Link to="/Shipping" style={{ color: 'white', textDecoration: 'none' }}
                  onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                  onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}>
                  &nbsp;Shipping&nbsp;</Link>
              </p>

              <p style={{ marginTop: '50px', fontSize: '0.9rem' }}>
                © Space Infinite | All Rights Reserved
              </p>
            </Typography>

          </Container>
        </Grid>

        {/* Pink Section */}
        <Grid size={12} style={{ backgroundColor: 'black', width: '100%' }}>
          <Container>
            <Grid size={12} style={{ borderTop: '2px dashed white' }}>
              <Typography
                variant="body1"
                color="white"
                textAlign="left"
                sx={{ fontSize: '0.9rem' }}
              >
                <p>
                  <span style={{ fontWeight: 'bold' }}>
                    Meta Policy:
                  </span>{' '}
                  This website is not a part of the Facebook or Instagram website or Meta Platforms, Inc. / Meta. Additionally, this site is NOT endorsed by Facebook or Instagram in ANY WAY.
                </p>
                <p>
                  FACEBOOK and INSTAGRAM are trademarks of Meta Platforms Inc.
                </p>
              </Typography>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default ShippingPage;
