import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	FormControlLabel,
	Paper,
	Stack,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import useRazorpay from "react-razorpay";
import { useEffect, useState } from "react";
import { consoleError, consoleLog, prefixBaseURL } from "../../Utils";

interface GstTP {
	sts: string,
	gstin: string,
	lgnm: string,
	tradeNam: string,
	pradrToStr: string
}

interface CouponCode {
	coupon_code: string,
	is_percent: boolean,
	discount: number,
	valid_to: string,
	valid_from: string
}

interface PackDetail {
	pack_id: string;
	pack_name_en: string;
	offer_price1: number;
}

interface PurchasePage03Props {
	globalData: any;
	setGlobalData: Function;
	handleBack: Function;
	handleNext: Function;
}

function validateGSTIN(gstin: string) {
	const gstinPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
	return gstinPattern.test(gstin);
}

export default function PurchasePage03({
	globalData,
	setGlobalData,
	handleBack,
	handleNext,
}: PurchasePage03Props) {

	const refreshBillingInfo = () => {
		var taxableAmt = 0,
			payableAmt = 0,
			totalGstAmt = 0,
			couponCodeAmt = 0,
			expediteDeliveryAmt = 0,
			packIdsCsv = "ADDON01_240805";
		packDetails.map((packDetail: PackDetail) => {
			taxableAmt += packDetail.offer_price1;
			return true;
		});
		if (globalData.expediteDeliveryChecked) {
			taxableAmt += edConfig.expedite_delivery_charges;
			expediteDeliveryAmt = edConfig.expedite_delivery_charges;
		}
		if (globalData.couponCodeIsValid) {
			const foundCC = couponCodes.find(
				(couponCode) => couponCode.coupon_code.toUpperCase() === globalData.couponCode
			);
			if (foundCC) {
				couponCodeAmt = foundCC.discount;
				if (foundCC.is_percent) {
					couponCodeAmt = (packDetails[0].offer_price1 * foundCC.discount / 100);
				}
			}
		}
		taxableAmt -= couponCodeAmt;
		totalGstAmt = (taxableAmt * globalData.gstPercent) / 100;
		payableAmt = taxableAmt + totalGstAmt;
		setGlobalData((prevState: any) => ({
			...prevState,
			expediteDeliveryAmt: expediteDeliveryAmt,
			couponCodeAmt: couponCodeAmt.toFixed(2),
			totalGstAmt: totalGstAmt.toFixed(2),
			taxableAmt: taxableAmt.toFixed(2),
			payableAmt: payableAmt.toFixed(2),
			packIdsCsv: packIdsCsv
		}));
	};
	useEffect(() => {
		refreshBillingInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [packDetails, setPackDetails] = useState<PackDetail[]>([]);
	useEffect(() => {
		fetch(prefixBaseURL("/v1/purchase/pack_name/ADDON01_240805"), {
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		}).then(function (response) {
			return response.json();
		}).then(function (result) {
			consoleLog(result);
			setPackDetails(result);
		}).catch(function (error) {
			consoleError(error);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [edConfig, setEdConfig] = useState({
		expedite_delivery_charges: 0,
		no_of_hours: 0,
	});
	useEffect(() => {
		fetch(prefixBaseURL("/v1/purchase/expedite_delivery"), {
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		}).then(function (response) {
			return response.json();
		}).then(function (result) {
			setEdConfig(result[0]);
			refreshBillingInfo();
		}).catch(function (error) {
			consoleError(error);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [couponCodes, setCouponCodes] = useState<CouponCode[]>([]);
	useEffect(() => {
		fetch(prefixBaseURL("/v1/purchase/coupon_code"), {
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		}).then(function (response) {
			return response.json();
		}).then(function (result) {
			setCouponCodes(result);
			refreshBillingInfo();
		}).catch(function (error) {
			consoleError(error);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		refreshBillingInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		globalData.expediteDeliveryChecked,
		globalData.couponCodeIsValid,
		packDetails,
	]);

	const handleCouponCodeChange = (e: { target: { value: string } }) => {
		const inputCC = e.target.value.toUpperCase().replace(" ", "").trim();
		const foundCC = couponCodes.find(
			(couponCode) => couponCode.coupon_code.toUpperCase() === inputCC
		);
		if (foundCC) {
			setGlobalData((prevState: any) => ({
				...prevState,
				couponCodeIsValid: true,
				couponCode: foundCC.coupon_code
			}));
		} else {
			setGlobalData((prevState: any) => ({
				...prevState,
				couponCodeAmt: 0,
				couponCode: inputCC,
				couponCodeIsValid: false,
			}));
		}
	};

	const [gstTP, setGstTP] = useState<GstTP>({
		sts: "",
		gstin: "",
		lgnm: "",
		tradeNam: "",
		pradrToStr: ""
	});
	const handleBuyerGSTNumberChange = (e: { target: { value: string } }) => {
		const inputGSTIN = e.target.value.toUpperCase().replace(" ", "").trim();
		if (inputGSTIN.length < 16) {
			const inputGSTINIsValid = validateGSTIN(inputGSTIN);
			setGlobalData((prevState: any) => ({
				...prevState,
				buyerGSTNumber: inputGSTIN,
				buyerGSTNumberIsValid: inputGSTINIsValid
			}));
			if (inputGSTINIsValid) {
				fetch(prefixBaseURL("/v1/gst/search?gstin=" + inputGSTIN), {
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				}).then(function (response) {
					return response.json();
				}).then(function (tp) {
					if (!tp.error) {
						tp.adadr.unshift(tp.pradr);
						tp.adadr.forEach(function (adr: { addr: any; }) {
							var toStrArr = [];
							var addr = adr.addr;
							if (addr.bno && addr.bno.trim().length > 0) toStrArr.push(addr.bno.trim());
							if (addr.bnm && addr.bnm.trim().length > 0) toStrArr.push(addr.bnm.trim());
							if (addr.flno && addr.flno.trim().length > 0) toStrArr.push("Floor: " + addr.flno.trim());
							if (addr.st && addr.st.trim().length > 0) toStrArr.push(addr.st.trim());
							if (addr.loc && addr.loc.trim().length > 0) toStrArr.push(addr.loc.trim());
							if (addr.dst && addr.dst.trim().length > 0) toStrArr.push(addr.dst.trim());
							if (addr.city && addr.city.trim().length > 0) toStrArr.push(addr.city.trim());
							if (addr.pncd && addr.pncd.trim().length > 0) toStrArr.push("Pin: " + addr.pncd.trim());
							if (addr.stcd && addr.stcd.trim().length > 0) toStrArr.push(addr.stcd.trim());
							toStrArr.push("India");
							addr.tostr = toStrArr.join(", ").replace(",,", ",").trim();
						});
						// var adadr = tp.adadr;
						delete tp.adadr;

						setGstTP((prevState: any) => ({
							...prevState,
							sts: tp.sts,
							lgnm: tp.lgnm,
							gstin: tp.gstin,
							tradeNam: tp.tradeNam,
							pradrToStr: tp.pradr.addr.tostr
						}));
					} else {
						consoleError("Error downloading TP by GSTIN from GSP: " + tp.error.message);
					}
				}).catch(function (error) {
					consoleError(error);
				});
			}
		}
	};

	const handleExpediteDeliveryChange = (e: {
		target: { checked: boolean };
	}) => {
		setGlobalData((prevState: any) => ({
			...prevState,
			expediteDeliveryChecked: e.target.checked,
		}));
	};

	const [rzpPymtInProgress, setRzpPymtInProgress] = useState<boolean>(false);
	const [Razorpay] = useRazorpay();

	const confirmAndPay = () => {
		consoleLog("Before Save: ", globalData);
		fetch(
			prefixBaseURL(
				"/v1/birth_details/addOrUpdateBirthAndBillDetails?skipBirthDetailUpdate=true"
			),
			{
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify(globalData),
			}
		).then(function (response) {
			return response.json();
		}).then(function (result) {
			consoleLog("After Save: ", result);
			setGlobalData((prevState: any) => ({
				...prevState,
				...result,
			}));
			createOrder(result.billId);
		}).catch(function (error) {
			consoleError(error);
		});
	};

	const createOrder = (billId: number) => {
		setRzpPymtInProgress(true);
		fetch(
			prefixBaseURL("/v1/rzp/createOrder?billId=" + billId),
			{
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			}
		).then(function (response) {
			return response.json();
		}).then(function (rzpOrder) {
			consoleLog("Rzp Order: ", rzpOrder);
			const options = {
				key: rzpOrder.key,
				order_id: rzpOrder.id,

				amount: rzpOrder.amount,
				currency: rzpOrder.currency,

				name: "Space Infinite",
				description: "Your Prediction Blueprint",

				handler: function (rzpSuccess: {
					razorpay_order_id: string;
					razorpay_signature: string;
					razorpay_payment_id: string;
				}) {
					const rzpResponse = {
						bill_id: billId,
						rzp_success: true,
						rzp_order_id: rzpSuccess.razorpay_order_id,
						rzp_signature: rzpSuccess.razorpay_signature,
						rzp_payment_id: rzpSuccess.razorpay_payment_id,
						rzp_error_code: 0,
						rzp_error_description: "",
					};
					saveRzpResponse(rzpResponse);
				},
				prefill: {
					contact: globalData.mobile,
					email: globalData.email,
				},
				readonly: {
					contact: true,
					email: true,
				},
			};
			const rzp1 = new Razorpay(options);
			rzp1.on(
				"payment.failed",
				function (rzpFailure: {
					error: {
						code: number;
						description: string;
						metadata: {
							order_id: string;
							payment_id: string;
						};
					};
				}) {
					const rzpResponse = {
						bill_id: billId,
						rzp_success: false,
						rzp_error_code: rzpFailure.error.code,
						rzp_order_id: rzpFailure.error.metadata.order_id,
						rzp_error_description: rzpFailure.error.description,
						rzp_payment_id: rzpFailure.error.metadata.payment_id,
					};
					saveRzpResponse(rzpResponse);
				}
			);
			rzp1.open();
		}).catch(function (error) {
			consoleError(error);
		});
	};

	const saveRzpResponse = (rzpResponse: {
		bill_id: number;
		rzp_success: boolean;
		rzp_error_code: number;
		rzp_order_id: string;
		rzp_error_description: string;
		rzp_payment_id: string;
	}) => {
		consoleLog(rzpResponse);
		fetch(prefixBaseURL("/v1/rzp/saveRzpResponse"), {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(rzpResponse),
		}).then(function (response) {
			return response.json();
		}).then(function (result) {
			consoleLog(result);
			if (rzpResponse.rzp_success && result) {
				window.location.replace("/yabr/report.jsp");
			} else {
				setRzpPymtInProgress(false);
			}
		}).catch(function (error) {
			consoleError(error);
		});
	};

	return (
		<Grid container alignItems="center" justifyContent="center">
			<Stack spacing={2} style={{ maxWidth: "700px" }}>
				<Card style={{ overflow: "visible", borderRadius: "7px" }}>
					<CardHeader
						title="Expedite Delivery (Optional)"
						titleTypographyProps={{ variant: "h6" }}
						style={{ backgroundColor: "orange", borderRadius: "7px" }}
					/>
					<CardContent>
						<Grid container spacing={2}>
							<Grid size={12}>
								<Typography>
									Select this option to receive the delivery
									of your personalized report within{" "}
									{edConfig.no_of_hours} hours of placing an
									order.
								</Typography>
								<FormControlLabel
									control={
										<Switch
											onChange={
												handleExpediteDeliveryChange
											}
											checked={
												globalData.expediteDeliveryChecked
											}
										/>
									}
									label={
										edConfig.no_of_hours +
										" Hours Expedite Delivery: " +
										edConfig.expedite_delivery_charges +
										" ₹"
									}
								/>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
				<Grid container spacing={2}>
					<Grid size={{ xs: 12, md: 12 }}>
						<Card
							style={{ overflow: "visible", borderRadius: "7px" }}
						>
							<CardHeader
								title="Coupon Code"
								titleTypographyProps={{ variant: "h6" }}
								style={{
									backgroundColor: "orange",
									borderRadius: "7px",
								}}
							/>
							<CardContent>
								<TextField
									fullWidth
									label="Coupon Code"
									value={globalData.couponCode}
									onChange={handleCouponCodeChange}
									sx={{
										input: {
											color: globalData.couponCodeIsValid
												? "green"
												: "red",
										},
									}}
								/>
							</CardContent>
						</Card>
					</Grid>
					{/* <Grid size={{ xs: 12, md: 6 }}>
						<Card
							style={{ overflow: "visible", borderRadius: "7px" }}
						>
							<CardHeader
								title="GST Number"
								titleTypographyProps={{ variant: "h6" }}
								style={{
									backgroundColor: "orange",
									borderRadius: "7px",
								}}
							/>
							<CardContent>
								<TextField
									fullWidth
									label="GST Number"
									value={globalData.buyerGSTNumber}
									onChange={handleBuyerGSTNumberChange}
									sx={{
										input: {
											color: globalData.buyerGSTNumberIsValid
												? "green"
												: "red",
										},
									}}
								/>
							</CardContent>
						</Card>
					</Grid> */}
				</Grid>
				{globalData.buyerGSTNumberIsValid && (
					<Card style={{ overflow: "visible", borderRadius: "7px" }}>
						<CardHeader
							title="My GST Details"
							titleTypographyProps={{ variant: "h6" }}
							style={{ backgroundColor: "orange", borderRadius: "7px" }}
						/>
						<CardContent>
							<Grid container spacing={2}>
								<Grid size={{ xs: 12, md: 9 }}>
									<TextField
										disabled
										fullWidth
										value={gstTP?.lgnm}
										label="Legal Name of Business"
									/>
								</Grid>
								<Grid size={{ xs: 12, md: 3 }}>
									<TextField
										disabled
										fullWidth
										label="Status"
										value={gstTP?.sts}
									/>
								</Grid>
								<Grid size={{ xs: 12, md: 12 }}>
									<TextField
										disabled
										fullWidth
										multiline
										value={gstTP?.pradrToStr}
										label="Principal Place of Business"
									/>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				)}
				<Card style={{ overflow: "visible", borderRadius: "7px" }}>
					<CardHeader
						title="Pack Details"
						titleTypographyProps={{ variant: "h6" }}
						style={{ backgroundColor: "orange", borderRadius: "7px" }}
					/>
					<CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
						<TableContainer component={Paper}>
							<Table size="small">
								<TableBody>
									{packDetails.map(
										(
											packDetail: PackDetail,
											index: number
										) => {
											return (
												<TableRow key={index}>
													<TableCell>
														<Typography>
															{
																packDetail.pack_name_en
															}
														</Typography>
													</TableCell>
													<TableCell align="right">
														<Typography noWrap>
															{
																packDetail.offer_price1
															}{" "}
															₹
														</Typography>
													</TableCell>
												</TableRow>
											);
										}
									)}
									{globalData.expediteDeliveryChecked && (
										<TableRow>
											<TableCell>
												<Typography>
													{edConfig.no_of_hours} Hours
													Expedite Delivery
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography noWrap>
													(+){" "}
													{
														edConfig.expedite_delivery_charges
													}{" "}
													₹
												</Typography>
											</TableCell>
										</TableRow>
									)}
									{globalData.couponCodeIsValid && (
										<TableRow>
											<TableCell>
												<Typography>
													Coupon Code Discount
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Typography noWrap>
													(-){" "}
													{globalData.couponCodeAmt} ₹
												</Typography>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</CardContent>
				</Card>
				<Card style={{ overflow: "visible", borderRadius: "7px" }}>
					<CardHeader
						title="Invoice Details"
						titleTypographyProps={{ variant: "h6" }}
						style={{ backgroundColor: "orange", borderRadius: "7px" }}
					/>
					<CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
						<TableContainer component={Paper}>
							<Table size="small">
								<TableBody>
									{/* <TableRow>
										<TableCell>
											<Typography>
												A) Total Taxable
											</Typography>
										</TableCell>
										<TableCell align="right">
											<Typography noWrap>
												{globalData.taxableAmt} ₹
											</Typography>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<Typography>
												B) GST ( A x{" "}
												{globalData.gstPercent}% )
											</Typography>
										</TableCell>
										<TableCell align="right">
											<Typography noWrap>
												{globalData.totalGstAmt} ₹
											</Typography>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<Typography>
												C) Total Payable ( A + B )
											</Typography>
										</TableCell>
										<TableCell align="right">
											<Typography noWrap>
												{globalData.payableAmt} ₹
											</Typography>
										</TableCell>
									</TableRow> */}
									<TableRow>
										<TableCell>
											<Typography>
												Total Payable
											</Typography>
										</TableCell>
										<TableCell align="right">
											<Typography noWrap>
												{globalData.payableAmt} ₹
											</Typography>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</CardContent>
				</Card>
				<Box sx={{ display: "flex", flexDirection: "row" }}>
					<Button
						onClick={() => handleBack()}
						color="inherit"
						sx={{ mr: 1 }}
					>
						Back
					</Button>
					<Box sx={{ flex: "1 1 auto" }} />
					<Button
						variant="contained"
						onClick={() => confirmAndPay()}
						disabled={globalData.payableAmt < 1 || rzpPymtInProgress}
					>
						Upgrade Your Report
					</Button>
				</Box>
			</Stack>
		</Grid>
	);
}
