import React, { useState } from 'react';
import {
    Accordion, AccordionSummary, AccordionDetails, Typography, Container
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const accordionData = [
    {
        heading: "Q: What is 'Your Abundance Blueprint'?",
        content: [
            "A: '<strong>Your Abundance Blueprint</strong>' serves as your personalized Astro-Vastu guide tailored for growth and success.",
            "This comprehensive report offers Astro-Vastu remedies, predictions, and insights derived from your birth chart, Nakshatra, and Vastu Shastra principles. It aims to enhance abundance and prosperity in various aspects of life by providing actionable guidance based on astrological and Vastu alignments."
        ]
    },
    {
        heading: "Q: What is included in the report?",
        content: [
            "A: 'Your Abundance Blueprint' is for individuals who have big dreams and are willing to put in the effort to achieve them. It is designed for those seeking guidance and remedies based on their astrology charts."
        ]
    },
    {
        heading: "Q: Is this report customized for me?",
        content: ["A: Yes, 'Your Abundance Blueprint' is tailored specifically to your birth details. It provides insights and remedies based on your individual characteristics, strengths, and challenges."
        ]
    },
    {
        heading: "Q: How does Astrology and Vastu work together?",
        content: ["A: Astrology and Vastu are complementary disciplines that work together to create harmony in your life. Astrology focuses on the influence of celestial bodies on your life, while Vastu Shastra deals with the energy flow and balance in your living spaces. By aligning these two aspects, you can unlock your hidden potential and attract abundance."
        ]
    },
    {
        heading: "Q: Can Astro-Vastu really change my life?",
        content: ["A: Yes, Astro-Vastu has the power to bring about positive transformations in various aspects of your life. By aligning yourself with the natural flow of energy and understanding the influences of astrology, you can attract success, prosperity, better health, and fulfilling relationships."
        ]
    },
    {
        heading: "Q: Are the remedies difficult to follow?",
        content: ["A: Not at all. The remedies and techniques provided in the report are simple and easy to implement. You won't have to make drastic changes or complicated rituals. They are designed to create a harmonious environment and attract positive energy into your life."
        ]
    },
    {
        heading: "Q: Can I trust the accuracy of the predictions?",
        content: ["A: Yes, the predictions and insights are based on the principles and knowledge of Astrology and Vastu Shastra.",
            "While nothing can be guaranteed with absolute certainty, the guidance provided in the report is highly accurate and has helped thousands of individuals transform their lives."
        ]
    },
    {
        heading: "Q: I live in a rented home, will the remedies still work for me?",
        content: ["A: Yes, the principles of Astrology and Vastu are universal.",
            "Regardless of whether you live in a rented home or your own property, the remedies provided in '<strong>Your Abundance Blueprint</strong>, can be applied to optimize the flow of positive energy in your living space. You can still rearrange your surroundings and make adjustments to create a harmonious environment that attracts abundance and fulfillment."

        ]
    },
    {
        heading: "Q: How do I access 'Your Abundance Blueprint'?",
        content: ["A: Upon placing your order, you can expect to receive access details within 4 business days. Access will be delivered to your email, containing a login URL accessible via OTP on your registered mobile number."
        ]
    },
    {
        heading: "Q: What if I have further questions or need assistance?",
        content: [
            "A: We are here to support you on your journey. If you have any questions or need assistance, you can email us at <a href='mailto:connect@destinydecoderr.com' style='color: #3b82f6; text-decoration: none;'>connect@destinydecoderr.com</a>.",
            "We are committed to ensuring your satisfaction and helping you make the most of 'Your Abundance Blueprint Report'."
        ]
    },
];

const AccordionComponent: React.FC = () => {
    const [expanded, setExpanded] = useState<string | false>('panel1');

    const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Container sx={{ maxWidth: { xs: '100%', sm: '50%', md: '50%' }}}>
            <Typography
                variant="body2"
                textAlign="center"
                fontWeight="bold"
                sx={{
                    fontFamily: `'Alatsi', sans-serif`, fontSize: '1.6rem',
                    mb: '50px', color: '#310033',
                    textTransform: 'uppercase',
                }}
            >
                <p>
                    Frequently Asked Questions
                </p>
            </Typography>
            {accordionData.map((item, index) => (
                <Accordion
                    key={index}
                    expanded={expanded === `panel${index + 1}`}
                    onChange={handleAccordionChange(`panel${index + 1}`)}
                    sx={{
                        borderRadius: '8px', boxShadow: 'none', border: '1px solid #cbd5e0', '&:before': {
                            display: 'none',
                        },
                        marginBottom: '16px',
                    }}
                >
                    <AccordionSummary
                        expandIcon={
                            <ExpandMoreIcon
                                sx={{
                                    color: expanded === `panel${index + 1}` ? '#3b82f6' : 'inherit',
                                }}
                            />
                        }
                    >
                        <Typography variant="body1" sx={{
                            fontSize: '1.3rem',
                            color: expanded === `panel${index + 1}` ? '#3b82f6' : 'inherit',
                        }}>
                            {item.heading}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {item.content.map((paragraph, idx) => (
                            <Typography
                                key={idx}
                                variant="body2"
                                paragraph
                                sx={{ fontSize: '1.2rem' }} dangerouslySetInnerHTML={{ __html: paragraph }}
                            />
                        ))}
                    </AccordionDetails>
                </Accordion>
            ))}
        </Container>
    );
};

export default AccordionComponent;