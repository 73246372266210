import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';

interface CountdownTimerProps {
  initialMinutes?: number;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ initialMinutes = 30 }) => {
  const [time, setTime] = useState({
    hours: Math.floor(initialMinutes / 60),
    minutes: initialMinutes % 60,
    seconds: 0,
  });

  useEffect(() => {
    const countdown = setInterval(() => {
      const { hours, minutes, seconds } = time;

      if (hours === 0 && minutes === 0 && seconds === 0) {
        clearInterval(countdown);
      } else if (seconds === 0) {
        setTime((prev) => ({
          hours: prev.minutes === 0 ? prev.hours - 1 : prev.hours,
          minutes: prev.minutes === 0 ? 59 : prev.minutes - 1,
          seconds: 59,
        }));
      } else {
        setTime((prev) => ({
          ...prev,
          seconds: prev.seconds - 1,
        }));
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [time]);

  const formatTime = (num: number) => (num < 10 ? `0${num}` : num);

  return (
    <Grid container spacing={4} justifyContent="center" alignItems="center">
      {/* Hours */}
      <Grid item>
        <Typography
          variant="h2"
          fontWeight="500"
          textAlign="center"
          sx={{ fontFamily: `'Poppins', sans-serif`, fontSize: '4rem' }}
        >
          {formatTime(time.hours)}
        </Typography>
        <Typography variant="body2" textAlign="center" sx={{ fontFamily: `'Poppins', sans-serif`, fontSize: '1rem' }}>
          Hours
        </Typography>
      </Grid>

      {/* Minutes */}
      <Grid item>
        <Typography
          variant="h2"
          fontWeight="500"
          textAlign="center"
          sx={{ fontFamily: `'Poppins', sans-serif`, fontSize: '4rem' }}
        >
          {formatTime(time.minutes)}
        </Typography>
        <Typography variant="body2" textAlign="center" sx={{ fontFamily: `'Poppins', sans-serif`, fontSize: '1rem' }}>
          Minutes
        </Typography>
      </Grid>

      {/* Seconds */}
      <Grid item>
        <Typography
          variant="h2"
          fontWeight="500"
          textAlign="center"
          sx={{ fontFamily: `'Poppins', sans-serif`, fontSize: '4rem' }}
        >
          {formatTime(time.seconds)}
        </Typography>
        <Typography variant="body2" textAlign="center" sx={{ fontFamily: `'Poppins', sans-serif`, fontSize: '1rem' }}>
          Seconds
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CountdownTimer;
