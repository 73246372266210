import Grid from "@mui/material/Grid2";
import {
	Box,
	Button,
	Card,
	CardContent,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import useRazorpay from "react-razorpay";
import { consoleError, consoleLog, prefixBaseURL } from "../../Utils";

interface PurchasePage04Props {
	globalData: any;
	setGlobalData: Function;
	handleBack: Function;
	handleNext: Function;
}

export default function PurchasePage04({
	globalData,
	setGlobalData,
	handleBack,
	handleNext,
}: PurchasePage04Props) {
	const queryParams = new URLSearchParams(window.location.search);
	let utmQueryParams = "&fbclid=" + queryParams.get("fbclid");
	utmQueryParams += "&utm_id=" + queryParams.get("utm_id");
	utmQueryParams += "&utm_source=" + queryParams.get("utm_source");
	utmQueryParams += "&yab_utm_id=" + queryParams.get("yab_utm_id");

	const lnMap = new Map();
	lnMap.set("en_US", "English");
	lnMap.set("hi_IN", "Hindi");
	lnMap.set("mr_IN", "Marathi");

	const [rzpPymtInProgress, setRzpPymtInProgress] = useState<boolean>(false);
	const [Razorpay] = useRazorpay();

	const confirmAndPay = () => {
		consoleLog("Before Save: ", globalData);
		fetch(
			prefixBaseURL(
				"/v1/birth_details/addOrUpdateBirthAndBillDetails"
			),
			{
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify(globalData),
			}
		).then(function (response) {
			return response.json();
		}).then(function (result) {
			consoleLog("After Save: ", result);
			setGlobalData((prevState: any) => ({
				...prevState,
				...result,
			}));
			createOrder(result.billId);
		}).catch(function (error) {
			consoleError(error);
		});
	};

	const createOrder = (billId: number) => {
		setRzpPymtInProgress(true);
		fetch(
			prefixBaseURL("/v1/rzp/createOrder?billId=" + billId + utmQueryParams),
			{
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			}
		).then(function (response) {
			return response.json();
		}).then(function (rzpOrder) {
			consoleLog("Rzp Order: ", rzpOrder);
			const options = {
				key: rzpOrder.key,
				order_id: rzpOrder.id,

				amount: rzpOrder.amount,
				currency: rzpOrder.currency,

				name: "Space Infinite",
				description: "Your Abundance Blueprint Report",

				handler: function (rzpSuccess: {
					razorpay_order_id: string;
					razorpay_signature: string;
					razorpay_payment_id: string;
				}) {
					const rzpResponse = {
						bill_id: billId,
						rzp_success: true,
						rzp_order_id: rzpSuccess.razorpay_order_id,
						rzp_signature: rzpSuccess.razorpay_signature,
						rzp_payment_id: rzpSuccess.razorpay_payment_id,
						rzp_error_code: 0,
						rzp_error_description: "",
					};
					saveRzpResponse(rzpResponse);
				},
				prefill: {
					contact: globalData.mobile,
					email: globalData.email,
				},
				readonly: {
					contact: true,
					email: true,
				},
			};
			const rzp1 = new Razorpay(options);
			rzp1.on(
				"payment.failed",
				function (rzpFailure: {
					error: {
						code: number;
						description: string;
						metadata: {
							order_id: string;
							payment_id: string;
						};
					};
				}) {
					const rzpResponse = {
						bill_id: billId,
						rzp_success: false,
						rzp_error_code: rzpFailure.error.code,
						rzp_order_id: rzpFailure.error.metadata.order_id,
						rzp_error_description: rzpFailure.error.description,
						rzp_payment_id: rzpFailure.error.metadata.payment_id,
					};
					saveRzpResponse(rzpResponse);
				}
			);
			rzp1.open();
		}).catch(function (error) {
			consoleError(error);
		});
	};

	const saveRzpResponse = (rzpResponse: {
		bill_id: number;
		rzp_success: boolean;
		rzp_error_code: number;
		rzp_order_id: string;
		rzp_error_description: string;
		rzp_payment_id: string;
	}) => {
		consoleLog(rzpResponse);
		fetch(prefixBaseURL("/v1/rzp/saveRzpResponse"), {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(rzpResponse),
		}).then(function (response) {
			return response.json();
		}).then(function (result) {
			consoleLog(result);
			if (rzpResponse.rzp_success && result) {
				window.location.replace("/yabr/thank-you.htm");
			} else {
				setRzpPymtInProgress(false);
			}
		}).catch(function (error) {
			consoleError(error);
		});
	};

	return (
		<Grid container alignItems="center" justifyContent="center">
			<Stack spacing={2} sx={{ maxWidth: "700px" }}>
				<Card style={{ overflow: "visible", borderRadius: "7px" }}>
					<CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
						<TableContainer component={Paper}>
							<Table
								size="small"
								sx={{ tableLayout: { md: "fixed" } }}
							>
								<TableBody>
									<TableRow>
										<TableCell>
											<Typography noWrap>
												First Name
											</Typography>
										</TableCell>
										<TableCell>
											<Typography noWrap>
												{globalData.firstName}
											</Typography>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<Typography noWrap>
												Last Name
											</Typography>
										</TableCell>
										<TableCell>
											<Typography noWrap>
												{globalData.lastName}
											</Typography>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<Typography noWrap>
												Gender
											</Typography>
										</TableCell>
										<TableCell>
											<Typography noWrap>
												{globalData.gender}
											</Typography>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<Typography noWrap>
												Mobile
											</Typography>
										</TableCell>
										<TableCell>
											<Typography noWrap>
												{globalData.mobile}
											</Typography>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<Typography noWrap>
												Email
											</Typography>
										</TableCell>
										<TableCell>
											<Typography noWrap>
												{globalData.email}
											</Typography>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<Typography noWrap>
												Report in
											</Typography>
										</TableCell>
										<TableCell>
											<Typography noWrap>
												{lnMap.get(globalData.lnKey)}
											</Typography>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<Typography noWrap>
												Report for
											</Typography>
										</TableCell>
										<TableCell>
											<Typography noWrap>
												{globalData.reportFor}
											</Typography>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<Typography noWrap>
												Date of Birth
											</Typography>
										</TableCell>
										<TableCell>
											<Typography noWrap>
												{dayjs(
													new Date(
														globalData.year,
														globalData.month - 1,
														globalData.day
													)
												).format("DD-MMM-YYYY")}
											</Typography>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<Typography noWrap>
												Time of Birth
											</Typography>
										</TableCell>
										<TableCell>
											<Typography noWrap>
												{dayjs(
													new Date(
														globalData.year,
														globalData.month - 1,
														globalData.day,
														globalData.hour,
														globalData.min
													)
												).format("hh:mm A")}
											</Typography>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<Typography noWrap>
												Place of Birth
											</Typography>
										</TableCell>
										<TableCell>
											<Typography>
												{globalData.place}
											</Typography>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell colSpan={2}>
											<Typography>
												Important! Details once confirmed cannot be modified under any circumstances.
											</Typography>
										</TableCell>
									</TableRow>

								</TableBody>
							</Table>
						</TableContainer>
					</CardContent>
				</Card>
				{/* <Card style={{ overflow: "visible", borderRadius: "7px" }}>
					<CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
						<TableContainer component={Paper}>
							<Table size="small">
								<TableBody>
									<TableRow>
										<TableCell>
											<Typography>
												A) Total Taxable
											</Typography>
										</TableCell>
										<TableCell align="right">
											<Typography noWrap>
												{globalData.taxableAmt} ₹
											</Typography>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<Typography>
												B) GST ( A x{" "}
												{globalData.gstPercent}% )
											</Typography>
										</TableCell>
										<TableCell align="right">
											<Typography noWrap>
												{globalData.totalGstAmt} ₹
											</Typography>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<Typography>
												<b>
													C) Total Payable ( A + B )
												</b>
											</Typography>
										</TableCell>
										<TableCell
											align="right"
											style={{ color: "green" }}
										>
											<Typography noWrap>
												<b>{globalData.payableAmt} ₹</b>
											</Typography>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</CardContent>
				</Card> */}
				<Box sx={{ display: "flex", flexDirection: "row" }}>
					<Button
						onClick={() => handleBack()}
						variant="outlined"
						color="inherit"
						sx={{ mr: 1 }}
					>
						Modify
					</Button>
					<Box sx={{ flex: "1 1 auto" }} />
					<Button
						variant="contained"
						disabled={rzpPymtInProgress}
						onClick={() => confirmAndPay()}
					>
						Confirm and Pay
					</Button>
				</Box>
			</Stack>
		</Grid>
	);
}
