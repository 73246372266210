// refund.tsx
import React from 'react';
import { Container, ThemeProvider, createTheme, Box, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
//import Refund from './Refund'; // Import Refund component
import Terms from './Terms'; // Import Refund component
import Privacy from './Privacy'; // Import Refund component
import Shipping from './Shipping'; // Import Refund component

const theme = createTheme({

  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: false,
        sx: {
          width: {
            xs: '90%',
            sm: '50%',
            md: '50%',
          },
          textAlign: 'center',
          py: '20px',
        },
      },
    },
  },

  typography: {
    fontFamily: `'Poppins', sans-serif`,
  },
});

const RefundPage: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>

      <Grid container direction="column" alignItems="center">
        {/* Red Section */}
        <Grid size={12} style={{ backgroundColor: 'black', width: '100%' }}>
          <Container>
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/Images/landingPage25.png`}
              alt="Life-Changing Bonuses"
              sx={{ width: { xs: '30%', sm: '20%', md: '20%' }, height: 'auto' }}
            />
            <Typography variant="body1" color="white" textAlign="center" sx={{
              fontSize: {
                xs: '1.4rem',
                sm: '1.5rem',
                md: '1.5rem'
              }
            }}>
              <p>
                REFUND POLICY
              </p>
            </Typography>
          </Container>
        </Grid>

        {/* Blue Section */}
        <Grid size={12} style={{ backgroundColor: 'white', width: '100%' }}>
          <Container>
            <Typography
              variant="body1"
              color="black"
              textAlign="left"
              sx={{
                fontSize: {
                  xs: '1rem',
                  sm: '1.2rem',
                  md: '1.2rem'
                }
              }}
            >
              <p>
                Refunds will only be granted for cancellation requests made within 2 hours from the time of processing the transaction.
              </p>

              <p>
                To request a cancellation and refund, please send an email to{' '}
                <Link
                  to={""}
                  rel="noopener noreferrer"
                  style={{
                    color: '#178bf6', textDecoration: 'none',
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                  onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
                >
                  connect@destinydecoderr.com
                </Link>

              </p>

              <p>
                However, please note that the acceptance of cancellation and refund requests is subject to the cancellation cut-off time from your purchase.
              </p>

              <p>
                If a refund is approved, it will be credited back to the original mode of payment used by you within 15 (Fifteen) working days from the date of the cancellation request.
              </p>

              <p>
                The Company will notify you of the successful initiation of the refund by email.
              </p>

              <p>
                Please be aware that we cannot be held liable for any misuse or usage of any information in the event of an electronic device being returned with such information.
              </p>

              <p>
                <strong>Note:</strong>{' '}This refund policy is in compliance with Indian legal requirements and may be subject to any additional terms and conditions as specified by the Company.
              </p>

            </Typography>
          </Container>
        </Grid>

        {/* Green Section */}
        <Grid size={12} style={{ backgroundColor: 'black', width: '100%' }}>
          <Container sx={{ width: { xs: '90%', sm: '35%', md: '35%' } }}>

            <Box display="flex" justifyContent="center">
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/Images/landingPage1.png`}
                alt="Life-Changing Bonuses"
                sx={{ width: '60%', height: 'auto' }}
              />
            </Box>

            <Box display="flex" justifyContent="center">
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/Images/down2.png`}
                alt="Life-Changing Bonuses"
                sx={{ width: '20%', height: 'auto', marginTop: '10px' }}
              />
            </Box>

            <Button
              variant="contained"
              sx={{
                width: '100%',
                p: '10px',
                m: '10px',
                fontSize: { xs: '1rem', sm: '1.2rem', md: '1.2rem' },
                fontWeight: 'bold',
                backgroundColor: '#f8d371',
                color: 'black',
                borderRadius: '50px',
                border: '3px solid white',
                textTransform: 'none',
                '&:hover': { backgroundColor: '#d4ac0d' },
              }}
            >
              Order Your Report Now!
            </Button>

            <Typography variant="body1" color="white" textAlign="center" sx={{ fontSize: '1.1rem', marginTop: '30px' }}>
              <p>
                <span style={{ fontWeight: 'bold' }}>SPACE INFINITE</span>
                <br />
                Tiara C-403, Lodha Upper Thane
                <br />
                Anjur - Mankoli Road, Thane 421302
                <br />
                📲 +91 7888744481
                <br />
                📧 vastu@rajhkumarsingh.com
              </p>

              {/* Footer Links */}
              <p style={{ marginTop: '50px', fontSize: '0.9rem' }}>
                <Link to="/Refund" style={{ color: 'white', textDecoration: 'none' }}
                  onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                  onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
                >
                  &nbsp;Refund&nbsp;</Link>|
                <Link to="/Terms" style={{ color: 'white', textDecoration: 'none' }}
                  onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                  onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
                >
                  &nbsp;Terms&nbsp;</Link>|
                <Link to="/Privacy" style={{ color: 'white', textDecoration: 'none' }}
                  onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                  onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
                >
                  &nbsp;Privacy Policy&nbsp;</Link>|
                <Link to="/Shipping" style={{ color: 'white', textDecoration: 'none' }}
                  onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                  onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}>
                  &nbsp;Shipping&nbsp;</Link>
              </p>

              <p style={{ marginTop: '50px', fontSize: '0.9rem' }}>
                © Space Infinite | All Rights Reserved
              </p>
            </Typography>

          </Container>
        </Grid>

        {/* Pink Section */}
        <Grid size={12} style={{ backgroundColor: 'black', width: '100%' }}>
          <Container>
            <Grid size={12} style={{ borderTop: '2px dashed white' }}>
              <Typography
                variant="body1"
                color="white"
                textAlign="left"
                sx={{ fontSize: '0.9rem' }}
              >
                <p>
                  <span style={{ fontWeight: 'bold' }}>
                    Meta Policy:
                  </span>{' '}
                  This website is not a part of the Facebook or Instagram website or Meta Platforms, Inc. / Meta. Additionally, this site is NOT endorsed by Facebook or Instagram in ANY WAY.
                </p>
                <p>
                  FACEBOOK and INSTAGRAM are trademarks of Meta Platforms Inc.
                </p>
              </Typography>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default RefundPage;
