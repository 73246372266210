import "react-phone-input-2/lib/material.css";

import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid2";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { consoleLog, consoleError, prefixBaseURL } from "../../Utils";
import { Button, CardHeader, Stack, TextField } from "@mui/material";
import dayjs from "dayjs";

export default function LayoutMakingDashboard() {
  let navigate = useNavigate();

  const [timeSlotsStatus, setTimeSlotsStatus] = useState({
    "05_30_PM": true,
    "11_30_AM": true,
    "06_00_PM": true,
    "07_00_PM": true,
    "09_30_AM": true,
    "09_00_AM": true,
    "10_30_AM": true,
    "01_30_PM": true,
    "06_30_PM": true,
    "11_00_AM": true,
    "03_00_PM": true,
    "02_30_PM": true,
    "12_30_PM": true,
    "04_00_PM": true,
    "10_00_AM": true,
    "12_00_PM": true,
    "02_00_PM": true,
    "05_00_PM": true,
    "04_30_PM": true,
    "03_30_PM": true,
    "01_00_PM": true,
  });

  const [inputs, setInputs] = useState({
    appointmentId: -1,
    appointmentDate: "",
    appointmentTime: "",
    appointmentStatus: "",
    layoutMakingSize: "",
    mobile: "",
    email: "",
    firstName: "",
    lastName: "",
    taxableAmt: 0,
    gstPercent: 18.0,
    igstAmt: 0,
    sgstAmt: 0,
    cgstAmt: 0,
    totalGstAmt: 0,
    payableAmt: 0,
  });

  const handleOpen = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      appointmentTime: e.target.innerText,
    }));
  };

  useEffect(() => {
    fetch(prefixBaseURL("/v1/lm/getLayoutMakingAppointmentDetails"), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(inputs),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (result) {
        consoleLog(result);
        setInputs(result);
      })
      .catch(function (error) {
        consoleError(error);
      });
  }, [inputs.appointmentTime]);

  const handleChange = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleMobileChange = (m) => {
    setInputs((prevState) => ({
      ...prevState,
      mobile: m,
    }));
  };

  const handleADateChange = (appointmentDate) => {
    setInputs((prevState) => ({
      ...prevState,
      appointmentDate: appointmentDate.format("YYYY-MM-DD"),
      appointmentTime: "",
    }));
    fetch(
      prefixBaseURL(
        "/v1/lm/timeSlotsStatusForAppointmentProcessing?appointmentDate=" +
          appointmentDate.format("YYYY-MM-DD")
      ),
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (result) {
        setTimeSlotsStatus(result);
      })
      .catch(function (error) {
        consoleError(error);
      });
  };

  return (
    <div>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Stack margin={1} spacing={2} style={{ maxWidth: "700px" }}>
          <Card style={{ overflow: "visible", borderRadius: "7px" }}>
            <CardHeader
              title="Appointment Dashboard"
              style={{ backgroundColor: "gold", borderRadius: "7px" }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                      maxDate={dayjs().add(15, "days")}
                      minDate={dayjs().add(1, "day")}
                      onChange={handleADateChange}
                      style={{ width: "100%" }}
                      disablePast
                    />
                  </LocalizationProvider>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                  ></Grid>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["09_00_AM"]}
                      >
                        09:00 AM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["09_30_AM"]}
                      >
                        09:30 AM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["10_00_AM"]}
                      >
                        10:00 AM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["10_30_AM"]}
                      >
                        10:30 AM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["11_00_AM"]}
                      >
                        11:00 AM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["11_30_AM"]}
                      >
                        11:30 AM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["12_00_PM"]}
                      >
                        12:00 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["12_30_PM"]}
                      >
                        12:30 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["01_00_PM"]}
                      >
                        01:00 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["01_30_PM"]}
                      >
                        01:30 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["02_00_PM"]}
                      >
                        02:00 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["02_30_PM"]}
                      >
                        02:30 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["03_00_PM"]}
                      >
                        03:00 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["03_30_PM"]}
                      >
                        03:30 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["04_00_PM"]}
                      >
                        04:00 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["04_30_PM"]}
                      >
                        04:30 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["05_00_PM"]}
                      >
                        05:00 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["05_30_PM"]}
                      >
                        05:30 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["06_00_PM"]}
                      >
                        06:00 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["06_30_PM"]}
                      >
                        06:30 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["07_00_PM"]}
                      >
                        07:00 PM
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {inputs.appointmentTime !== "" && (
            <Card style={{ overflow: "visible", borderRadius: "7px" }}>
              <CardHeader
                title="Client Details"
                style={{ backgroundColor: "gold", borderRadius: "7px" }}
              />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid size={6}>
                    <TextField
                      name="appoimentDate"
                      label="Appointment Date"
                      value={inputs.appointmentDate}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      disabled
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#000000",
                        },
                      }}
                    />
                  </Grid>
                  <Grid size={6}>
                    <TextField
                      name="appoimentDate"
                      label="Appointment Date"
                      value={inputs.appointmentTime}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      disabled
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#000000",
                        },
                      }}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <PhoneInput
                      enableSearch
                      country={"in"}
                      preferredCountries={["in"]}
                      value={inputs.mobile}
                      onChange={handleMobileChange}
                      disabled
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#000000",
                        },
                      }}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <TextField
                      name="email"
                      label="Email"
                      value={inputs.email}
                      onChange={handleChange}
                      variant="outlined"
                      type="email"
                      fullWidth
                      disabled
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#000000",
                        },
                      }}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <TextField
                      name="firstName"
                      label="First Name"
                      value={inputs.firstName}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      disabled
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#000000",
                        },
                      }}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <TextField
                      name="lastName"
                      label="Last Name"
                      value={inputs.lastName}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      disabled
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#000000",
                        },
                      }}
                    />
                  </Grid>
                  <Grid size={6}>
                    <TextField
                      name="layoutMakingSize"
                      label="Layout Size"
                      value={inputs.layoutMakingSize}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      disabled
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#000000",
                        },
                      }}
                    />
                  </Grid>
                  <Grid size={6}>
                    <TextField
                      name="appointmentStatus"
                      label="Appointment Status"
                      value={inputs.appointmentStatus}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      disabled
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#000000",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Stack>
      </Grid>
    </div>
  );
}
