// refund.tsx
import React from 'react';
import { Container, ThemeProvider, createTheme, Box, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Refund from './Refund'; // Import Refund component
import Terms from './Terms'; // Import Refund component
//import Privacy from './PrivacyPolicy'; // Import Refund component
import Shipping from './Shipping'; // Import Refund component

const theme = createTheme({

  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: false,
        sx: {
          width: {
            xs: '90%',
            sm: '50%',
            md: '50%',
          },
          textAlign: 'center',
          py: '20px',
        },
      },
    },
  },

  typography: {
    fontFamily: `'Poppins', sans-serif`,
  },
});

const PrivacyPage: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>

      <Grid container direction="column" alignItems="center">
        {/* Red Section */}
        <Grid size={12} style={{ backgroundColor: 'black', width: '100%' }}>
          <Container>
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/Images/landingPage25.png`}
              alt="Life-Changing Bonuses"
              sx={{ width: { xs: '30%', sm: '20%', md: '20%' }, height: 'auto' }}
            />
            <Typography variant="body1" color="white" textAlign="center" sx={{
              fontSize: {
                xs: '1.4rem',
                sm: '1.5rem',
                md: '1.5rem'
              }
            }}>
              <p>
                PRIVACY POLICY
              </p>
            </Typography>
          </Container>
        </Grid>

        {/* Blue Section */}
        <Grid size={12} style={{ backgroundColor: 'white', width: '100%' }}>
          <Container>
            <Typography
              variant="body1"
              color="black"
              textAlign="left"
              sx={{
                fontSize: {
                  xs: '1rem',
                  sm: '1.2rem',
                  md: '1.2rem'
                }
              }}
            >

              <p>
                This Statement of Privacy applies to our practices in connection with the Website, i.e.{" "}
                <Link
                  to={""}
                  rel="noopener noreferrer"
                  style={{
                    color: '#178bf6', textDecoration: 'none',
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                  onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
                >
                  https://yourabundanceblueprint.info
                </Link>
                <br />
                Protecting your private information is our priority.

              </p>

              <p>
                This Privacy Policy describes the treatment of information collected and posted on the Website.
              </p>

              <p>
                The Company fully respects your right to privacy and would like to safeguard the information you provide to the Company and will not use or collect any personal information on this Website without your permission. Any Personal information which you voluntarily submit/provide to the Company will be treated with the highest standard of security and confidentiality and shall be used strictly for the purposes of the services provided by the Company and Website only. By using the Website, you consent to the data practices described in this statement.
              </p>

              <p>
                By using the Website you signify your agreement to the terms of this Privacy Policy and the Company reserves the right, in its sole discretion to change, modify, add and/or delete portions of the terms of this Privacy Policy at any time with immediate effect of such change.
              </p>

              <strong>1. Collection and Use of your Personal Information</strong>

              <p>
                a. The Company will use the personal data which you have provided to the Company for the purposes of fulfilling your application for any of the Company's services and products, credit checks in the case of applications, and for understanding how the Company can improve its services to you. Where appropriate, the Company may also use the information for internal research on the Company's user demographics, interests and behaviour, for providing you with the latest updates, where the Company thinks you may be interested in its services and/or services of some of the Company's affiliates or third parties. The Company may combine personal information collected from you with information from other services or third parties to provide a better user experience, that display customized content and advertising including customizing content for you. The Company requires the compliance of its Privacy Policy where the Company hires third parties for aid and assistance in case of upgradation of the Company's research and analysis, and technological advancement. The Company may also share the information with any third party, in limited circumstances, for national security and integrity or on an order from any court or competent authority.
              </p>

              <p>
                b. The Company will also use your Internet Protocol address and e-mail addresses to help diagnose problems with the Company's server and to administer the Website. If you don't want your personal information to be displayed to third party visitors you need to contact the Company in that behalf. Please remember that any information that is disclosed in the public areas of this Website becomes in fact public and you should exercise caution when deciding to post your personal data.
              </p>

              <p>
                c. The Company's primary purpose in collecting information from you is to allow you to use the Website and various features and services made available on or in relation to the Website or any websites or for any services provided by the Company or its affiliates or its various service providers or business partners and advertisers; to record your information and details as permitted and required under applicable laws, statutory direction or judiciary orders; to serve various promotion materials and advertising materials to you; and such other uses as provided in this Privacy Policy. The Company may also use your information for content recommendations, transactional emails or to provide you with information, direct marketing, online and offline advertising and other materials regarding products, services and other offers from time to time in connection with the Company or its parent, subsidiary and affiliated companies and its joint ventures. You can decide not to participate in the promotion if you do not want your data to be shared. Promotions that run on the Website may be sponsored by companies other than the Company or may be co-sponsored by another company. Some or all data collected during a promotion may be shared with the sponsor.
              </p>

              <p>
                d. Additionally, the Company further states that the Company tracks down some traffic data. Each time a visitor comes to the Website, the Websiteâs server logs some basic technical information such as browser and platform, visitor's domain name, referral data, etc. The Company is also processing the visitor's activity through the Website. All this information is statistical and doesn't allow the Company to identify you or contact you in any way. Also, the Company uses third-party advertising companies to serve ads when you visit the Website. These companies may use information (not including your name, address, email address, or telephone number) about your visits to this and other websites in order to provide advertisements about goods and services of interest to you. If you would like more information about this practice and to know your choices about not having this information used by these companies, contact the Company.
              </p>

              <strong>2. Compliance with Laws</strong>

              <p>
                a. The Company co-operates with mandated government and law enforcement agencies or to any third parties by an order under law for the time being in force to enforce and comply with the law. The Company will also disclose any information about you to the government and law enforcement agencies or private parties without your permission as the Company, in its sole discretion, believes it to be necessary or appropriate to respond to valid claims and legal processes, to protect the property and rights of the Website or a third party, to protect the safety of the public or any person, or to prevent or stop any illegal, unethical or legally actionable activity. The Company may also provide your information to various tax authorities upon any demand or request from them.
              </p>

              <p>
                b. Unfortunately, no data transmissions over the internet can be guaranteed to be completely secure. As a result, while we strive to protect your personal information, the Company cannot ensure or warrant the security of any information you transmit to the Company via the internet, and you do so at your own risk. Once the Company has received your transmission, the Company will use its reasonable endeavour to ensure its safety & security on the Company's systems. Nothing contained in this policy is intended to create a contract or agreement between the Company and any user providing identifiable information in any form.
              </p>

              <p>
                c. We will take all necessary steps to comply with this Privacy Policy; however, to the extent permitted by law, nothing in this policy is intended to hold the Company liable for any failure to comply with this Privacy Policy.
              </p>

            </Typography>
          </Container>
        </Grid>

        {/* Green Section */}
        <Grid size={12} style={{ backgroundColor: 'black', width: '100%' }}>
          <Container sx={{ width: { xs: '90%', sm: '35%', md: '35%' } }}>

            <Box display="flex" justifyContent="center">
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/Images/landingPage1.png`}
                alt="Life-Changing Bonuses"
                sx={{ width: '60%', height: 'auto' }}
              />
            </Box>

            <Box display="flex" justifyContent="center">
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/Images/down2.png`}
                alt="Life-Changing Bonuses"
                sx={{ width: '20%', height: 'auto', marginTop: '10px' }}
              />
            </Box>

            <Button
              variant="contained"
              sx={{
                width: '100%',
                p: '10px',
                m: '10px',
                fontSize: { xs: '1rem', sm: '1.2rem', md: '1.2rem' },
                fontWeight: 'bold',
                backgroundColor: '#f8d371',
                color: 'black',
                borderRadius: '50px',
                border: '3px solid white',
                textTransform: 'none',
                '&:hover': { backgroundColor: '#d4ac0d' },
              }}
            >
              Order Your Report Now!
            </Button>

            <Typography variant="body1" color="white" textAlign="center" sx={{ fontSize: '1.1rem', marginTop: '30px' }}>
              <p>
                <span style={{ fontWeight: 'bold' }}>SPACE INFINITE</span>
                <br />
                Tiara C-403, Lodha Upper Thane
                <br />
                Anjur - Mankoli Road, Thane 421302
                <br />
                📲 +91 7888744481
                <br />
                📧 vastu@rajhkumarsingh.com
              </p>

              {/* Footer Links */}
              <p style={{ marginTop: '50px', fontSize: '0.9rem' }}>
                <Link to="/Refund" style={{ color: 'white', textDecoration: 'none' }}
                  onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                  onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
                >
                  &nbsp;Refund&nbsp;</Link>|
                <Link to="/Terms" style={{ color: 'white', textDecoration: 'none' }}
                  onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                  onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
                >
                  &nbsp;Terms&nbsp;</Link>|
                <Link to="/Privacy" style={{ color: 'white', textDecoration: 'none' }}
                  onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                  onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
                >
                  &nbsp;Privacy Policy&nbsp;</Link>|
                <Link to="/Shipping" style={{ color: 'white', textDecoration: 'none' }}
                  onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                  onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}>
                  &nbsp;Shipping&nbsp;</Link>
              </p>

              <p style={{ marginTop: '50px', fontSize: '0.9rem' }}>
                © Space Infinite | All Rights Reserved
              </p>
            </Typography>

          </Container>
        </Grid>

        {/* Pink Section */}
        <Grid size={12} style={{ backgroundColor: 'black', width: '100%' }}>
          <Container>
            <Grid size={12} style={{ borderTop: '2px dashed white' }}>
              <Typography
                variant="body1"
                color="white"
                textAlign="left"
                sx={{ fontSize: '0.9rem' }}
              >
                <p>
                  <span style={{ fontWeight: 'bold' }}>
                    Meta Policy:
                  </span>{' '}
                  This website is not a part of the Facebook or Instagram website or Meta Platforms, Inc. / Meta. Additionally, this site is NOT endorsed by Facebook or Instagram in ANY WAY.
                </p>
                <p>
                  FACEBOOK and INSTAGRAM are trademarks of Meta Platforms Inc.
                </p>
              </Typography>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default PrivacyPage;
