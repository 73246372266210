import "react-phone-input-2/lib/material.css";

import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid2";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { consoleLog, consoleError, prefixBaseURL } from "../../Utils";
import {
  Box,
  Button,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import useRazorpay from "react-razorpay";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function LayoutMakingAppointment() {
  let navigate = useNavigate();
  const [Razorpay] = useRazorpay();

  const [open, setOpen] = useState(false);

  const handleOpen = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      appointmentTime: e.target.innerText,
    }));
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const handleConfirm = (e) => {
    setOpen(false);
    consoleLog(inputs);
    // navigate("/ab/purchase/PurchasePage02");
    fetch(prefixBaseURL("/v1/lm/addLayoutMakingAppointment"), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(inputs),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (appointmentId) {
        setInputs((prevState) => ({
          ...prevState,
          appointmentId: appointmentId,
        }));
        handleADateChange(dayjs(inputs.appointmentDate));
        consoleLog("Appointment Id: " + appointmentId);
        createOrder(appointmentId);
      })
      .catch(function (error) {
        consoleError(error);
      });
  };

  const createOrder = (appointmentId) => {
    fetch(prefixBaseURL("/v1/lm/createOrder?appointmentId=" + appointmentId), {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (rzpOrder) {
        consoleLog("Rzp Order: ", rzpOrder);
        const options = {
          key: rzpOrder.key,
          order_id: rzpOrder.id,

          name: "Layout Making",
          description: "Layout Making",

          handler: function (rzpSuccess) {
            const rzpResponse = {
              bill_id: appointmentId,
              rzp_success: true,
              rzp_order_id: rzpSuccess.razorpay_order_id,
              rzp_signature: rzpSuccess.razorpay_signature,
              rzp_payment_id: rzpSuccess.razorpay_payment_id,
            };
            saveRzpResponse(rzpResponse);
          },
          prefill: {
            contact: inputs.mobile,
            email: inputs.email,
          },
          readonly: {
            contact: true,
            email: true,
          },
        };
        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (rzpFailure) {
          const rzpResponse = {
            bill_id: appointmentId,
            rzp_success: false,
            rzp_error_code: rzpFailure.error.code,
            rzp_order_id: rzpFailure.error.metadata.order_id,
            rzp_error_description: rzpFailure.error.description,
            rzp_payment_id: rzpFailure.error.metadata.payment_id,
          };
          saveRzpResponse(rzpResponse);
        });
        rzp1.open();
      })
      .catch(function (error) {
        consoleError(error);
      });
  };

  const saveRzpResponse = (rzpResponse) => {
    consoleLog(rzpResponse);
    fetch(prefixBaseURL("/v1/lm/saveRzpResponse"), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(rzpResponse),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (result) {
        consoleLog(result);
      })
      .catch(function (error) {
        consoleError(error);
      });
  };

  const [inputs, setInputs] = useState({
    appointmentId: -1,
    appointmentDate: "",
    appointmentTime: "",
    appointmentStatus: "Booking",
    layoutMakingSize: "",
    mobile: "919819924183",
    email: "shail.surve@gmail.com",
    firstName: "Shailesh",
    lastName: "Surve",
    taxableAmt: 0,
    gstPercent: 18.0,
    igstAmt: 0,
    sgstAmt: 0,
    cgstAmt: 0,
    totalGstAmt: 0,
    payableAmt: 0,
  });

  const [layoutMakingSizes, setLayoutMakingSizes] = useState([]);

  useEffect(() => {
    const getLayoutMakingSizes = async () => {
      const res = await fetch(prefixBaseURL("/v1/lm/layoutMakingPrices"), {
        headers: { "Content-Type": "application/json" },
      });
      const response = await res.json();
      setLayoutMakingSizes(response);
    };
    getLayoutMakingSizes();
  }, []);

  const [timeSlotsStatus, setTimeSlotsStatus] = useState({
    "05_30_PM": true,
    "11_30_AM": true,
    "06_00_PM": true,
    "07_00_PM": true,
    "09_30_AM": true,
    "09_00_AM": true,
    "10_30_AM": true,
    "01_30_PM": true,
    "06_30_PM": true,
    "11_00_AM": true,
    "03_00_PM": true,
    "02_30_PM": true,
    "12_30_PM": true,
    "04_00_PM": true,
    "10_00_AM": true,
    "12_00_PM": true,
    "02_00_PM": true,
    "05_00_PM": true,
    "04_30_PM": true,
    "03_30_PM": true,
    "01_00_PM": true,
  });

  const handleChange = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleMobileChange = (m) => {
    setInputs((prevState) => ({
      ...prevState,
      mobile: m,
    }));
  };

  const handleLmSizeChange = (e) => {
    const layoutMakingSizeObj = layoutMakingSizes.find(
      (lmSize) => lmSize.layout === e.target.value
    );
    setInputs((prevState) => ({
      ...prevState,
      taxableAmt: layoutMakingSizeObj.price,
      layoutMakingSize: layoutMakingSizeObj.layout,
      totalGstAmt: (layoutMakingSizeObj.price * 18) / 100,
      payableAmt:
        layoutMakingSizeObj.price + (layoutMakingSizeObj.price * 18) / 100,
    }));
  };

  const handleADateChange = (appointmentDate) => {
    setInputs((prevState) => ({
      ...prevState,
      appointmentDate: appointmentDate.format("YYYY-MM-DD"),
    }));
    fetch(
      prefixBaseURL(
        "/v1/lm/timeSlotsStatusForAppointmentBooking?appointmentDate=" +
          appointmentDate.format("YYYY-MM-DD")
      ),
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (result) {
        setTimeSlotsStatus(result);
      })
      .catch(function (error) {
        consoleError(error);
      });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Appointment Details
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Dear {inputs.firstName},<br />
            Scheduling your Appointment for
            <br />
            {inputs.appointmentDate} at {inputs.appointmentTime}.
          </Typography>
          <br />
          <Stack spacing={2} direction={"row"}>
            <Button onClick={handleClose} variant="outlined">
              Make Correction
            </Button>
            <Button onClick={handleConfirm} variant="contained">
              Confirm
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Stack margin={1} spacing={2} style={{ maxWidth: "700px" }}>
          <Card style={{ overflow: "visible", borderRadius: "7px" }}>
            <CardHeader
              title="Personal Details"
              style={{ backgroundColor: "gold", borderRadius: "7px" }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <PhoneInput
                    enableSearch
                    country={"in"}
                    preferredCountries={["in"]}
                    value={inputs.mobile}
                    error={inputs.mobile === ""}
                    onChange={handleMobileChange}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <TextField
                    name="email"
                    label="Email"
                    value={inputs.email}
                    error={inputs.email === ""}
                    onChange={handleChange}
                    variant="outlined"
                    type="email"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <TextField
                    name="firstName"
                    label="First Name"
                    value={inputs.firstName}
                    error={inputs.firstName === ""}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <TextField
                    name="lastName"
                    label="Last Name"
                    value={inputs.lastName}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card style={{ overflow: "visible", borderRadius: "7px" }}>
            <CardHeader
              title="Area Size & Price"
              style={{ backgroundColor: "gold", borderRadius: "7px" }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <FormControl fullWidth>
                    <InputLabel id="layout-making-size-select-label">
                      Area Size *
                    </InputLabel>
                    <Select
                      name="layoutMakingSize"
                      label="Area Size *"
                      onChange={handleLmSizeChange}
                      value={inputs.layoutMakingSize}
                      error={inputs.layoutMakingSize === ""}
                      labelId="layout-making-size-select-label"
                      required
                    >
                      {layoutMakingSizes.map((layoutMakingSize) => (
                        <MenuItem
                          key={layoutMakingSize.srNo}
                          value={layoutMakingSize.layout}
                        >
                          {layoutMakingSize.layout}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Stack spacing={2} direction={"row"}>
                    <Grid size={4}>
                      <TextField
                        name="taxableAmt"
                        label="Price"
                        value={inputs.taxableAmt}
                        inputProps={{ style: { textAlign: "end" } }}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        disabled
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                    </Grid>
                    <Grid size={4}>
                      <TextField
                        name="totalGstAmt"
                        label="GST"
                        value={inputs.totalGstAmt}
                        inputProps={{ style: { textAlign: "end" } }}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        disabled
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                    </Grid>
                    <Grid size={4}>
                      <TextField
                        name="payableAmt"
                        label="Amount"
                        value={inputs.payableAmt}
                        inputProps={{ style: { textAlign: "end" } }}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        disabled
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card style={{ overflow: "visible", borderRadius: "7px" }}>
            <CardHeader
              title="Appointment Details"
              style={{ backgroundColor: "gold", borderRadius: "7px" }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                      maxDate={dayjs().add(15, "days")}
                      minDate={dayjs().add(1, "day")}
                      onChange={handleADateChange}
                      style={{ width: "100%" }}
                      disablePast
                    />
                  </LocalizationProvider>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography>
                      Appointment Date: {inputs.appointmentDate}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["09_00_AM"]}
                      >
                        09:00 AM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["09_30_AM"]}
                      >
                        09:30 AM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["10_00_AM"]}
                      >
                        10:00 AM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["10_30_AM"]}
                      >
                        10:30 AM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["11_00_AM"]}
                      >
                        11:00 AM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["11_30_AM"]}
                      >
                        11:30 AM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["12_00_PM"]}
                      >
                        12:00 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["12_30_PM"]}
                      >
                        12:30 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["01_00_PM"]}
                      >
                        01:00 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["01_30_PM"]}
                      >
                        01:30 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["02_00_PM"]}
                      >
                        02:00 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["02_30_PM"]}
                      >
                        02:30 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["03_00_PM"]}
                      >
                        03:00 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["03_30_PM"]}
                      >
                        03:30 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["04_00_PM"]}
                      >
                        04:00 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["04_30_PM"]}
                      >
                        04:30 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["05_00_PM"]}
                      >
                        05:00 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["05_30_PM"]}
                      >
                        05:30 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["06_00_PM"]}
                      >
                        06:00 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["06_30_PM"]}
                      >
                        06:30 PM
                      </Button>
                    </Grid>
                    <Grid size={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleOpen}
                        disabled={timeSlotsStatus["07_00_PM"]}
                      >
                        07:00 PM
                      </Button>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      marginTop={2}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography>
                        {inputs.appointmentTime} IST (India Standard Time)
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Stack>
      </Grid>
    </div>
  );
}
