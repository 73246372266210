import React, { useState, useEffect, useMemo } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
	Autocomplete,
	BaseTextFieldProps,
	InputAdornment,
	ListSubheader,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { consoleError, Place, prefixBaseURL } from "../Utils";
import {
	defaultCountries,
	FlagImage,
	parseCountry,
} from "react-international-phone";

interface MuiCountryPlacesProps extends BaseTextFieldProps {
	value: string;
	defaultCountry: string;
	onPlaceChange: Function;
	onCountryChange: Function;
}

export default function MuiCountryPlaces({
	value,
	defaultCountry,
	onPlaceChange,
	onCountryChange,
	...restProps
}: MuiCountryPlacesProps) {
	const [options, setOptions] = useState([]);

	const getData = (searchTerm: string) => {
		const fetchURL = prefixBaseURL(
			"/v1/places?c=" + country + "&q=" + searchTerm
		);
		fetch(fetchURL, {
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (myJson) {
				// consoleLog(fetchURL + ", results: ", JSON.stringify(myJson));
				const updatedOptions = myJson.map((p: Place) => {
					return { place: p.place };
				});
				setOptions(updatedOptions);
				onPlaceChange(myJson[0]);
			})
			.catch(function (error) {
				consoleError(error);
			});
	};

	const onInputChange = (event: any, value: string, reason: string) => {
		if (value) {
			getData(value);
		} else {
			setOptions([]);
		}
	};

	const [searchText, setSearchText] = useState("");
	const [country, setCountry] = useState("in");
	useEffect(() => {
		onCountryChange(country);
		getData("");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [country]);

	const containsText = (text: string, searchText: string) =>
		text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
	const filteredCountries = useMemo(
		() =>
			defaultCountries.filter((c) => {
				return containsText(c[0], searchText);
			}),
		[searchText]
	);

	return (
		<Stack direction={"row"}>
			<Select
				value={country}
				onChange={(e) => setCountry(e.target.value)}
				renderValue={(value) => (
					<FlagImage iso2={value} style={{ display: "flex" }} />
				)}
			>
				{/* TextField is put into ListSubheader so that it doesn't
                				act as a selectable item in the menu
                				i.e. we can click the TextField without triggering any selection.*/}
				<ListSubheader>
					<TextField
						autoFocus
						fullWidth
						size="small"
						placeholder="Type to search..."
						slotProps={{
							input: {
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
							},
						}}
						onChange={(e) => setSearchText(e.target.value)}
						onKeyDown={(e) => {
							if (e.key !== "Escape") {
								// Prevents autoselecting item while typing (default Select behaviour)
								e.stopPropagation();
							}
						}}
					/>
				</ListSubheader>
				{filteredCountries.map((c) => {
					const country = parseCountry(c);
					return (
						<MenuItem key={country.iso2} value={country.iso2}>
							<FlagImage
								iso2={country.iso2}
								style={{ marginRight: "8px" }}
							/>
							<Typography marginRight="8px">
								{country.name}
							</Typography>
							<Typography color="gray">
								+{country.dialCode}
							</Typography>
						</MenuItem>
					);
				})}
			</Select>
			<Autocomplete
				fullWidth
				key={country}
				options={options}
				onInputChange={onInputChange}
				getOptionLabel={(option: Place) => option.place}
				isOptionEqualToValue={(option, value) =>
					option.place === value.place
				}
				renderInput={(params) => (
					<TextField {...params} {...restProps} variant="outlined" />
				)}
			/>
		</Stack>
	);
}
