import React, { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { consoleError, consoleLog, Place, prefixBaseURL } from "../../Utils";
import {
	Box,
	Button,
	CardHeader,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
} from "@mui/material";
import { MuiPhone } from "../../components/MuiPhone";
import MuiCountryPlaces from "../../components/MuiCountryPlaces";

interface PurchasePage01Props {
	globalData: any;
	setGlobalData: Function;
	handleNext: Function;
}

export default function PurchasePage01({
	globalData,
	setGlobalData,
	handleNext,
}: PurchasePage01Props) {
	useEffect(() => {
		if (globalData.billId > -1) {
			fetch(
				prefixBaseURL(
					"/v1/birth_details/birthDetailByBillId?billId=" +
					globalData.billId
				),
				{
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				}
			).then(function (response) {
				return response.json();
			}).then(function (result) {
				consoleLog(result);
				setGlobalData((prevState: any) => ({
					...prevState,
					...result,
				}));
			}).catch(function (error) {
				consoleError(error);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalData.billId]);

	const handleChange = (e: { target: { name: string; value: string } }) => {
		setGlobalData((prevState: any) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	const handleMobileChange = (m: string) => {
		setGlobalData((prevState: any) => ({
			...prevState,
			mobile: m,
		}));
	};

	const handleCountryChange = (c: string) => {
		setGlobalData((prevState: any) => ({
			...prevState,
			country: c,
			place: "",
			tzone: "",
			lat: 0,
			lon: 0,
		}));
	};

	const handlePlaceChange = (p: Place) => {
		if (p) {
			setGlobalData((prevState: any) => ({
				...prevState,
				place: p.place,
				tzone: p.tzone,
				lat: p.lat,
				lon: p.lon,
			}));
		} else {
			setGlobalData((prevState: any) => ({
				...prevState,
				place: "",
				tzone: "",
				lat: 0,
				lon: 0,
			}));
		}
	};

	// Validates that the input string is a valid date formatted as "mm/dd/yyyy"
	const isValidDate = (dateString: string) => {
		// First check for the pattern
		if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
			return false;

		// Parse the date parts to integers
		var parts = dateString.split("/");
		var day = parseInt(parts[1], 10);
		var month = parseInt(parts[0], 10);
		var year = parseInt(parts[2], 10);

		// Check the ranges of month and year
		if (year < 1000 || year > 3000 || month == 0 || month > 12)
			return false;

		var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

		// Adjust for leap years
		if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
			monthLength[1] = 29;

		// Check the range of the day
		return day > 0 && day <= monthLength[month - 1];
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (globalData.mobile.length < 10) {
			alert("Please enter valid WhatsApp Number");
			return false;
		}
		if (!isValidDate(globalData.month + '/' + globalData.day + '/' + globalData.year)) {
			alert("Please enter valid Birth Date");
			return false;
		}
		if ((document.getElementById("bdForm") as HTMLFormElement)?.checkValidity()) {
			consoleLog("globalData: ", globalData);
			fetch(
				prefixBaseURL(
					"/v1/birth_details/addOrUpdateBirthAndBillDetails"
				),
				{
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
					body: JSON.stringify(globalData),
				}
			).then(function (response) {
				return response.json();
			}).then(function (result) {
				consoleLog("billId: " + result);
				setGlobalData((prevState: any) => ({
					...prevState,
					...result,
				}));
				handleNext();
			}).catch(function (error) {
				consoleError(error);
			});
		}
	};

	return (
		<div>
			<form id="bdForm" onSubmit={handleSubmit}>
				<Grid container alignItems="center" justifyContent="center">
					<Stack spacing={2} style={{ maxWidth: "700px" }}>
						<Card
							style={{ overflow: "visible", borderRadius: "7px" }}
						>
							<CardHeader
								title="Personal Details"
								titleTypographyProps={{ variant: "h6" }}
								style={{
									backgroundColor: "orange",
									borderRadius: "7px",
								}}
							/>
							<CardContent>
								<Grid container spacing={2}>
									<Grid size={{ xs: 12, md: 6 }}>
										<MuiPhone
											required
											fullWidth
											name="mobile"
											defaultCountry={"in"}
											label="WhatsApp Number"
											value={globalData.mobile}
											error={globalData.mobile === ""}
											onChange={handleMobileChange}
										/>
									</Grid>
									<Grid size={{ xs: 12, md: 6 }}>
										<TextField
											name="email"
											label="Email ID"
											value={globalData.email}
											error={globalData.email === ""}
											onChange={handleChange}
											variant="outlined"
											type="email"
											fullWidth
											required
										/>
									</Grid>
									<Grid size={{ xs: 12, md: 6 }}>
										<TextField
											name="firstName"
											label="First Name"
											value={globalData.firstName}
											error={globalData.firstName === ""}
											onChange={handleChange}
											variant="outlined"
											fullWidth
											required
										/>
									</Grid>
									<Grid size={{ xs: 12, md: 6 }}>
										<TextField
											name="lastName"
											label="Last Name"
											value={globalData.lastName}
											onChange={handleChange}
											variant="outlined"
											fullWidth
										/>
									</Grid>
									<Grid size={6}>
										<FormControl fullWidth>
											<InputLabel id="gender-select-label">
												Gender *
											</InputLabel>
											<Select
												name="gender"
												label="Gender *"
												value={globalData.gender}
												error={globalData.gender === ""}
												onChange={handleChange}
												labelId="gender-select-label"
												required
											>
												<MenuItem value={"Male"}>
													Male
												</MenuItem>
												<MenuItem value={"Female"}>
													Female
												</MenuItem>
											</Select>
										</FormControl>
									</Grid>
									<Grid size={6}>
										<FormControl fullWidth>
											<InputLabel id="report-language-select-label">
												Report Language *
											</InputLabel>
											<Select
												name="lnKey"
												value={globalData.lnKey}
												onChange={handleChange}
												error={globalData.lnKey === ""}
												label="Report Language *"
												labelId="report-language-select-label"
												required
											>
												<MenuItem value={"en_US"}>
													English
												</MenuItem>
												{/* <MenuItem value={"hi_IN"}>
													Hindi
												</MenuItem>
												<MenuItem value={"mr_IN"}>
													Marathi
												</MenuItem> */}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
						<Card
							style={{ overflow: "visible", borderRadius: "7px" }}
						>
							<CardHeader
								title="Birth Details"
								titleTypographyProps={{ variant: "h6" }}
								style={{
									backgroundColor: "orange",
									borderRadius: "7px",
								}}
							/>
							<CardContent>
								<Grid container spacing={2}>
									<Grid size={{ xs: 12, md: 6 }}>
										<MuiCountryPlaces
											required
											fullWidth
											name="place"
											defaultCountry={"in"}
											label="Place of Birth"
											value={globalData.place}
											error={globalData.place === ""}
											onPlaceChange={handlePlaceChange}
											onCountryChange={
												handleCountryChange
											}
										/>
									</Grid>
									<Grid size={{ xs: 12, md: 6 }}>
										<FormControl fullWidth>
											<InputLabel id="report-for-select-label">
												Report for *
											</InputLabel>
											<Select
												required
												name="reportFor"
												label="Report for *"
												value={globalData.reportFor}
												onChange={handleChange}
												error={
													globalData.reportFor === ""
												}
												labelId="report-for-select-label"
											>
												<MenuItem value={"Myself"}>
													Myself
												</MenuItem>
												<MenuItem value={"Spouse"}>
													Spouse
												</MenuItem>
												<MenuItem value={"Children"}>
													Children
												</MenuItem>
												<MenuItem value={"Parent"}>
													Parent
												</MenuItem>
												<MenuItem value={"Sibling"}>
													Sibling
												</MenuItem>
												<MenuItem value={"Relative"}>
													Relative
												</MenuItem>
												<MenuItem value={"Friend"}>
													Friend
												</MenuItem>
											</Select>
										</FormControl>
										{/* <CountrySelect
											name="country"
											label="Country"
											value={globalData.country}
											error={globalData.country === ""}
											onChange={handleCountryChange}
										/> */}
									</Grid>
									<Grid size={{ xs: 12, md: 6 }}>
										<Stack spacing={2} direction={"row"}>
											<Grid size={4}>
												<FormControl fullWidth>
													<InputLabel id="day-select-label">
														Day *
													</InputLabel>
													<Select
														name="day"
														label="Day *"
														value={globalData.day}
														onChange={handleChange}
														error={
															globalData.day ===
															""
														}
														labelId="day-select-label"
														required
													>
														<MenuItem value={"1"}>
															01
														</MenuItem>
														<MenuItem value={"2"}>
															02
														</MenuItem>
														<MenuItem value={"3"}>
															03
														</MenuItem>
														<MenuItem value={"4"}>
															04
														</MenuItem>
														<MenuItem value={"5"}>
															05
														</MenuItem>
														<MenuItem value={"6"}>
															06
														</MenuItem>
														<MenuItem value={"7"}>
															07
														</MenuItem>
														<MenuItem value={"8"}>
															08
														</MenuItem>
														<MenuItem value={"9"}>
															09
														</MenuItem>
														<MenuItem value={"10"}>
															10
														</MenuItem>
														<MenuItem value={"11"}>
															11
														</MenuItem>
														<MenuItem value={"12"}>
															12
														</MenuItem>
														<MenuItem value={"13"}>
															13
														</MenuItem>
														<MenuItem value={"14"}>
															14
														</MenuItem>
														<MenuItem value={"15"}>
															15
														</MenuItem>
														<MenuItem value={"16"}>
															16
														</MenuItem>
														<MenuItem value={"17"}>
															17
														</MenuItem>
														<MenuItem value={"18"}>
															18
														</MenuItem>
														<MenuItem value={"19"}>
															19
														</MenuItem>
														<MenuItem value={"20"}>
															20
														</MenuItem>
														<MenuItem value={"21"}>
															21
														</MenuItem>
														<MenuItem value={"22"}>
															22
														</MenuItem>
														<MenuItem value={"23"}>
															23
														</MenuItem>
														<MenuItem value={"24"}>
															24
														</MenuItem>
														<MenuItem value={"25"}>
															25
														</MenuItem>
														<MenuItem value={"26"}>
															26
														</MenuItem>
														<MenuItem value={"27"}>
															27
														</MenuItem>
														<MenuItem value={"28"}>
															28
														</MenuItem>
														<MenuItem value={"29"}>
															29
														</MenuItem>
														<MenuItem value={"30"}>
															30
														</MenuItem>
														<MenuItem value={"31"}>
															31
														</MenuItem>
													</Select>
												</FormControl>
											</Grid>
											<Grid size={4}>
												<FormControl fullWidth>
													<InputLabel id="month-select-label">
														Month *
													</InputLabel>
													<Select
														name="month"
														label="Month *"
														value={globalData.month}
														onChange={handleChange}
														error={
															globalData.month ===
															""
														}
														labelId="month-select-label"
														required
													>
														<MenuItem value={"1"}>
															Jan
														</MenuItem>
														<MenuItem value={"2"}>
															Feb
														</MenuItem>
														<MenuItem value={"3"}>
															Mar
														</MenuItem>
														<MenuItem value={"4"}>
															Apr
														</MenuItem>
														<MenuItem value={"5"}>
															May
														</MenuItem>
														<MenuItem value={"6"}>
															Jun
														</MenuItem>
														<MenuItem value={"7"}>
															Jul
														</MenuItem>
														<MenuItem value={"8"}>
															Aug
														</MenuItem>
														<MenuItem value={"9"}>
															Sep
														</MenuItem>
														<MenuItem value={"10"}>
															Oct
														</MenuItem>
														<MenuItem value={"11"}>
															Nov
														</MenuItem>
														<MenuItem value={"12"}>
															Dec
														</MenuItem>
													</Select>
												</FormControl>
											</Grid>
											<Grid size={4}>
												<TextField
													name="year"
													label="Year"
													value={globalData.year}
													onChange={handleChange}
													error={
														globalData.year === ""
													}
													variant="outlined"
													type="number"
													fullWidth
													required
												/>
											</Grid>
										</Stack>
									</Grid>
									<Grid size={{ xs: 12, md: 6 }}>
										<Stack spacing={2} direction={"row"}>
											<Grid size={7}>
												<FormControl fullWidth>
													<InputLabel id="hour-select-label">
														Hour *
													</InputLabel>
													<Select
														name="hour"
														label="Hour *"
														value={globalData.hour}
														onChange={handleChange}
														error={
															globalData.hour ===
															""
														}
														labelId="hour-select-label"
														required
													>
														<MenuItem value={"0"}>
															00 (12 AM)
														</MenuItem>
														<MenuItem value={"1"}>
															01 (01 AM)
														</MenuItem>
														<MenuItem value={"2"}>
															02 (02 AM)
														</MenuItem>
														<MenuItem value={"3"}>
															03 (03 AM)
														</MenuItem>
														<MenuItem value={"4"}>
															04 (04 AM)
														</MenuItem>
														<MenuItem value={"5"}>
															05 (05 AM)
														</MenuItem>
														<MenuItem value={"6"}>
															06 (06 AM)
														</MenuItem>
														<MenuItem value={"7"}>
															07 (07 AM)
														</MenuItem>
														<MenuItem value={"8"}>
															08 (08 AM)
														</MenuItem>
														<MenuItem value={"9"}>
															09 (09 AM)
														</MenuItem>
														<MenuItem value={"10"}>
															10 (10 AM)
														</MenuItem>
														<MenuItem value={"11"}>
															11 (11 AM)
														</MenuItem>
														<MenuItem value={"12"}>
															12 (12 PM)
														</MenuItem>
														<MenuItem value={"13"}>
															13 (01 PM)
														</MenuItem>
														<MenuItem value={"14"}>
															14 (02 PM)
														</MenuItem>
														<MenuItem value={"15"}>
															15 (03 PM)
														</MenuItem>
														<MenuItem value={"16"}>
															16 (04 PM)
														</MenuItem>
														<MenuItem value={"17"}>
															17 (05 PM)
														</MenuItem>
														<MenuItem value={"18"}>
															18 (06 PM)
														</MenuItem>
														<MenuItem value={"19"}>
															19 (07 PM)
														</MenuItem>
														<MenuItem value={"20"}>
															20 (08 PM)
														</MenuItem>
														<MenuItem value={"21"}>
															21 (09 PM)
														</MenuItem>
														<MenuItem value={"22"}>
															22 (10 PM)
														</MenuItem>
														<MenuItem value={"23"}>
															23 (11 PM)
														</MenuItem>
													</Select>
												</FormControl>
											</Grid>
											<Grid size={5}>
												<FormControl fullWidth>
													<InputLabel id="min-select-label">
														Min *
													</InputLabel>
													<Select
														name="min"
														label="Min *"
														value={globalData.min}
														onChange={handleChange}
														error={
															globalData.min ===
															""
														}
														labelId="min-select-label"
														required
													>
														<MenuItem value={"0"}>
															00
														</MenuItem>
														<MenuItem value={"1"}>
															01
														</MenuItem>
														<MenuItem value={"2"}>
															02
														</MenuItem>
														<MenuItem value={"3"}>
															03
														</MenuItem>
														<MenuItem value={"4"}>
															04
														</MenuItem>
														<MenuItem value={"5"}>
															05
														</MenuItem>
														<MenuItem value={"6"}>
															06
														</MenuItem>
														<MenuItem value={"7"}>
															07
														</MenuItem>
														<MenuItem value={"8"}>
															08
														</MenuItem>
														<MenuItem value={"9"}>
															09
														</MenuItem>
														<MenuItem value={"10"}>
															10
														</MenuItem>
														<MenuItem value={"11"}>
															11
														</MenuItem>
														<MenuItem value={"12"}>
															12
														</MenuItem>
														<MenuItem value={"13"}>
															13
														</MenuItem>
														<MenuItem value={"14"}>
															14
														</MenuItem>
														<MenuItem value={"15"}>
															15
														</MenuItem>
														<MenuItem value={"16"}>
															16
														</MenuItem>
														<MenuItem value={"17"}>
															17
														</MenuItem>
														<MenuItem value={"18"}>
															18
														</MenuItem>
														<MenuItem value={"19"}>
															19
														</MenuItem>
														<MenuItem value={"20"}>
															20
														</MenuItem>
														<MenuItem value={"21"}>
															21
														</MenuItem>
														<MenuItem value={"22"}>
															22
														</MenuItem>
														<MenuItem value={"23"}>
															23
														</MenuItem>
														<MenuItem value={"24"}>
															24
														</MenuItem>
														<MenuItem value={"25"}>
															25
														</MenuItem>
														<MenuItem value={"26"}>
															26
														</MenuItem>
														<MenuItem value={"27"}>
															27
														</MenuItem>
														<MenuItem value={"28"}>
															28
														</MenuItem>
														<MenuItem value={"29"}>
															29
														</MenuItem>
														<MenuItem value={"30"}>
															30
														</MenuItem>
														<MenuItem value={"31"}>
															31
														</MenuItem>
														<MenuItem value={"32"}>
															32
														</MenuItem>
														<MenuItem value={"33"}>
															33
														</MenuItem>
														<MenuItem value={"34"}>
															34
														</MenuItem>
														<MenuItem value={"35"}>
															35
														</MenuItem>
														<MenuItem value={"36"}>
															36
														</MenuItem>
														<MenuItem value={"37"}>
															37
														</MenuItem>
														<MenuItem value={"38"}>
															38
														</MenuItem>
														<MenuItem value={"39"}>
															39
														</MenuItem>
														<MenuItem value={"40"}>
															40
														</MenuItem>
														<MenuItem value={"41"}>
															41
														</MenuItem>
														<MenuItem value={"42"}>
															42
														</MenuItem>
														<MenuItem value={"43"}>
															43
														</MenuItem>
														<MenuItem value={"44"}>
															44
														</MenuItem>
														<MenuItem value={"45"}>
															45
														</MenuItem>
														<MenuItem value={"46"}>
															46
														</MenuItem>
														<MenuItem value={"47"}>
															47
														</MenuItem>
														<MenuItem value={"48"}>
															48
														</MenuItem>
														<MenuItem value={"49"}>
															49
														</MenuItem>
														<MenuItem value={"50"}>
															50
														</MenuItem>
														<MenuItem value={"51"}>
															51
														</MenuItem>
														<MenuItem value={"52"}>
															52
														</MenuItem>
														<MenuItem value={"53"}>
															53
														</MenuItem>
														<MenuItem value={"54"}>
															54
														</MenuItem>
														<MenuItem value={"55"}>
															55
														</MenuItem>
														<MenuItem value={"56"}>
															56
														</MenuItem>
														<MenuItem value={"57"}>
															57
														</MenuItem>
														<MenuItem value={"58"}>
															58
														</MenuItem>
														<MenuItem value={"59"}>
															59
														</MenuItem>
													</Select>
												</FormControl>
											</Grid>
										</Stack>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
						<Box sx={{ display: "flex", flexDirection: "row" }}>
							{/* <Button color="inherit" sx={{ mr: 1 }} disabled>
								Back
							</Button> */}
							<Box sx={{ flex: "1 1 auto" }} />
							<Button type="submit" variant="contained">
								Next
							</Button>
						</Box>
					</Stack>
				</Grid>
			</form>
		</div>
	);
}
