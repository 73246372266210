import AddOnPackPurchase from "./pages/purchase_add_on_pack/AddOnPackPurchase";
import NewUserPurchase from "./pages/purchase_new_kundli_pack/NewUserPurchase";
import { Routes, Route, HashRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from "@mui/material";
import LayoutMakingAppointment from "./pages/layout_making/LayoutMakingAppointment";
import LayoutMakingDashboard from "./pages/layout_making/LayoutMakingDashboard";
import LandingPage from "./LandingPage";
import Refund from "./Refund";
import Shipping from "./Shipping";
import Terms from "./Terms";
import Privacy from "./Privacy";

const theme = createTheme({
	colorSchemes: {
		dark: true,
	},
});

export default function App() {
	return (
		<ThemeProvider theme={theme}>
			<HashRouter>
				<Routes>
					<Route path={`/`} Component={LandingPage} />
					<Route path={`/Terms`} Component={Terms} />
					<Route path={`/Refund`} Component={Refund} />
					<Route path={`/Privacy`} Component={Privacy} />
					<Route path={`/Shipping`} Component={Shipping} />
					<Route path={`/NewUserPurchase`} Component={NewUserPurchase} />
					<Route path={`/AddOnPackPurchase/:bdId`} Component={AddOnPackPurchase} />
					<Route path={`/LayoutMakingDashboard`} Component={LayoutMakingDashboard} />
					<Route path={`/LayoutMakingAppointment`} Component={LayoutMakingAppointment} />
				</Routes>
			</HashRouter>
			{/* <NewUserPurchase /> */}
			{/* <AddOnPackPurchase /> */}
			{/* <Router>
				<Routes>
					<Route path="/ab/purchase" element={<HorizontalLinearStepper />} />
				</Routes>
			</Router> */}
		</ThemeProvider >
	);
}
