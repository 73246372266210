import { Box, Button, Typography, CssBaseline, ThemeProvider, createTheme, Container, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Link } from 'react-router-dom';
import CountdownTimer from './CountdownTimer';
import CancelIcon from '@mui/icons-material/Cancel';
import AccordionComponent from './AccordionComponent';

const theme = createTheme({
	typography: {
		fontFamily: `'Poppins', sans-serif`,
	},
});

export default function LandingPage() {
	const queryParams = new URLSearchParams(window.location.search);
	let linkToNewUserPurchaseComponent = "/NewUserPurchase";
	linkToNewUserPurchaseComponent += "?fbclid=" + queryParams.get("fbclid");
	linkToNewUserPurchaseComponent += "&utm_id=" + queryParams.get("utm_id");
	linkToNewUserPurchaseComponent += "&utm_source=" + queryParams.get("utm_source");
	linkToNewUserPurchaseComponent += "&yab_utm_id=" + queryParams.get("yab_utm_id");

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline /> {/* Reset default browser styling */}

			{/* Fixed Top Bar with Background Color #192734 */}
			<Box
				sx={{
					width: '100%',
					backgroundColor: '#192734',
					color: 'white',
					textAlign: 'center',
					p: 2,
					position: 'fixed', top: 0,
					left: 0,
					zIndex: 999,
				}}
			>
				<Typography variant="body1" component="span">
					Use GET100 for ₹100/- OFF 👉{' '}
					<Link to={linkToNewUserPurchaseComponent} style={{ color: '#f4eb00', fontWeight: 'bold' }}>ORDER NOW</Link>
				</Typography>
			</Box>

			{/* New Section A with Background Color #310033, starting from the very top */}
			<Box
				sx={{
					width: '100%',
					backgroundColor: '#310033',
					display: 'flex',
					flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', paddingTop: '90px', paddingBottom: '50px',
				}}
			>
				<Container sx={{ maxWidth: { xs: '100%', sm: '50%', md: '50%' } }}>
					<Grid container alignItems="flex-start" justifyContent="center" spacing={2}>
						<Grid size={12}>
							<Typography
								variant="body1"
								color="white"
								textAlign="center"
								px="10px"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}
							>
								10X Your Manifesting Power
							</Typography>

							<Typography
								variant="body2"
								fontWeight="bold"
								sx={{
									fontFamily: `'Alatsi', sans-serif`,
									fontSize: {
										xs: '1.7rem',
										sm: '1.8rem',
										md: '1.8rem'
									},
									marginTop: '16px',
									textAlign: {
										xs: 'left',
										sm: 'center',
										md: 'center',
									},
								}}
							>
								<span style={{ color: 'white', textDecoration: 'underline' }}>
									FROM FINANCIAL STRESS TO LIMITLESS ABUNDANCE
								</span>
								<br /> {/* Line break between the two parts */}
								<span style={{ color: '#f8d370' }}>
									DISCOVER PROVEN ASTRO-VASTU SOLUTIONS FOR MONEY AND SUCCESS TO LIVE YOUR DREAM LIFE
								</span>
							</Typography>

							{/* New Typography for additional text */}
							<Typography
								variant="body1"
								color="white"
								textAlign="center"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									},
									marginTop: '20px',
									textAlign: {
										xs: 'left',
										sm: 'center',
										md: 'center',
									}
								}}             >
								<p>
									Join Thousands Who’ve Unlocked Success! Get your Personal Report + 5 FREE Life Changing Bonuses (₹8,985/- Value) + FREE Vastu and Astrology Course (₹9,997/-)
									<br />
									- All for ₹799!
								</p>
							</Typography>

							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/landingPage1.png`}
								alt="Life-Changing Bonuses"
								sx={{
									width: '100%', height: 'auto',
									marginTop: '20px',
									borderRadius: '8px',
								}}
							/>

							<Typography
								variant="body1"
								color="white"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}, marginTop: '18px'
								}}
							>
								<p>
									Your Abundance Blueprint Report combines the power of Vedic Astrology and Vastu Shastra to create a personalised roadmap to financial prosperity and wish fulfilment.
								</p>

								<p>
									We analyse your unique birth chart to identify any potential money blocks and create customised Astro-Vastu solutions to help you overcome them.
								</p>
							</Typography>

							<Typography
								variant="body1"
								color="white"
								textAlign="center"
								sx={{
									fontSize: {
										xs: '0.7rem',
										sm: '0.9rem',
										md: '0.9rem'
									}, mt: '50px'
								}}
							>
								Regular Price: <span style={{ textDecoration: 'line-through' }}>₹5,999/-</span> | Save 86%
							</Typography>

							<Link to={linkToNewUserPurchaseComponent}>
								<Button
									variant="contained"
									sx={{
										width: '100%',
										mt: '10px',
										p: '12px',
										fontSize: {
											xs: '1rem',
											sm: '1.2rem',
											md: '1.2rem'
										},
										backgroundColor: '#f1c40f',
										color: 'black',
										borderRadius: '50px',
										border: '2px solid white',
										textTransform: 'none',
										'&:hover': {
											backgroundColor: '#d4ac0d',
										},
									}}
								>
									Order Your Report Now
								</Button>
							</Link>

							<Typography
								variant="body1"
								color="white"
								textAlign="center"
								sx={{
									fontSize: {
										xs: '0.7rem',
										sm: '0.9rem',
										md: '0.9rem'
									}, mt: '10px'
								}}
							>
								3700+ Report Delivered
								<br />
								Start Manifesting Your Dreams Today!
								<br />
								Use{' '}
								<span style={{ color: '#f4eb03', fontWeight: 'bold' }}>
									GET100
								</span>{' '}
								for ₹100/- OFF
							</Typography>

						</Grid>
					</Grid>
				</Container>
			</Box>

			<Box
				sx={{
					width: '100%',
					backgroundColor: 'white',
					display: 'flex',
					flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', paddingY: '50px',
				}}
			>
				<Container sx={{ maxWidth: { xs: '100%', sm: '50%', md: '50%' } }}>
					<Grid container alignItems="flex-start" justifyContent="center" spacing={2}>
						<Grid size={12}>
							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}, marginTop: '10px'
								}}             >
								😥 Are you tired of feeling financially trapped? I know the struggle is real and it can be very frustrating.
							</Typography>

							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/landingPage2.png`}
								alt="Life-Changing Bonuses"
								sx={{
									width: '100%', height: 'auto',
									marginTop: '15px',
									filter: 'grayscale(100%)',
								}}
							/>

							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}, marginTop: '10px'
								}}             >
								<p>Let's be honest.</p>
								<p>
									Financial stress is a real drag. It doesn’t matter if you're an ambitious entrepreneur chasing your big break, a busy professional juggling work and family, a student dreaming of a brighter future, or a dedicated homemaker keeping the family ship afloat – <span style={{ color: '#310033', fontWeight: 'bold' }}>money worries can zap the joy out of life</span>.
								</p>
								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>Picture this:</span> You wake up every morning, heart pounding with the weight of unpaid bills. The fear of an empty bank account is a constant shadow.
								</p>

							</Typography>

							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/landingPage3.png`}
								alt="Life-Changing Bonuses"
								sx={{
									width: '100%', height: 'auto',
									marginTop: '15px',
									filter: 'grayscale(100%)',
								}}
							/>

							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}, marginTop: '10px'
								}}             >
								<p>
									And despite working hard, that dream life feels farther away than ever. You've probably tried every quick fix, money miracle, various courses and drastic lifestyle change under the sun. But it's like running on a treadmill – <span style={{ color: '#310033', fontWeight: 'bold' }}>you're going nowhere fast.</span>
								</p>
								<p>
									This money stress monster creeps into every corner of your life. It steals your peace, dims your dreams, and even strains your relationships.
								</p>
								<p>
									You're not alone in this battle. <span style={{ color: '#310033', fontWeight: 'bold' }}>Millions of people are trapped in this endless cycle, yearning for a financial escape</span>.
								</p>

							</Typography>

							<Typography
								variant="body2"
								textAlign="center"
								fontWeight="bold"
								sx={{
									fontFamily: `'Alatsi', sans-serif`, fontSize: '1.5rem',
									my: '50px', color: '#310033',
									textTransform: 'uppercase',
								}}
							>
								<p>
									Ever wondered?
								</p>
								<p>
									why youR HARD EARNED money seems to slip through your fingers?
								</p>
							</Typography>

							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}
							>
								<p>
									The reason could be a simple imbalance between your home's energy (Vastu) and your personal astrological influences (Birth Chart)!
								</p>

							</Typography>

							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/landingPage4.png`}
								alt="Life-Changing Bonuses"
								sx={{
									width: '100%', height: 'auto',
									marginTop: '15px',
								}}
							/>

							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}, marginTop: '10px'
								}}             >
								<p>
									For instance, assume you have Gemini (Mithun) rashi in the 2nd House of your birth chart, which governs Money and Accumulations, <span style={{ color: '#310033', fontWeight: 'bold' }}>it's crucial to understand its impact</span>.
								</p>

							</Typography>

							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/landingPage5.png`}
								alt="Life-Changing Bonuses"
								sx={{
									width: '100%', height: 'auto',
									marginTop: '15px',
								}}
							/>

							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}, marginTop: '10px'
								}}             >
								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>Mercury</span>, the ruler of Gemini, is associated with the North direction of your property.
								</p>
								<p>
									Any Vastu dosha or imbalance in the North can disrupt the natural flow of wealth in your home.
								</p>

							</Typography>

							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/landingPage6.png`}
								alt="Life-Changing Bonuses"
								sx={{
									width: '100%', height: 'auto',
									marginTop: '15px',
								}}
							/>

							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}, marginTop: '10px'
								}}             >
								<p>
									Now, picture your toilet seat positioned in the North zone – <i style={{ color: '#310033' }}>Mercury's domain.</i>
								</p>

								<p>
									This misplaced placement disrupts the positive energy flow associated with Mercury and could significantly hinder the flow of money in your household.
								</p>

								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>The outcome?</span>
								</p>

								<p>
									Financial instability, difficulty managing cash flow, and a struggle to accumulate wealth.
								</p>

								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>But there's a solution!</span>
								</p>

								<p>
									By harnessing the power of Astro-Vastu, we can cultivate an environment that promotes your prosperity and well-being. Such simple adjustments can make a big difference.
								</p>
							</Typography>

							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/landingPage7.png`}
								alt="Life-Changing Bonuses"
								sx={{
									width: '100%', height: 'auto',
									marginTop: '15px',
								}}
							/>

							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}, marginTop: '10px'
								}}             >

								<p>
									I'm Acharya Rajkumar Singh, and I've helped countless people just like you achieve their dreams. With a stellar reputation and thousands of happy clients worldwide, I've guided families to happier, more prosperous lives.
								</p>

								<p>
									Today, with the help of <span style={{ color: '#310033', fontWeight: 'bold' }}>Your Abundance Blueprint Report</span> I will guide you on how to align yourself with Mother Nature to attract an abundance of money, success, and prosperity into your life.
									<br />
									Our personalised report offers a revolutionary approach that combines the ancient wisdom of astrology and Vastu to create a personalised roadmap to wealth and happiness.
								</p>

								<p>
									Unlike <span style={{ color: '#310033', fontWeight: 'bold' }}>generic astrological or vastu advice</span>, our report is completely personalised to your individual birth chart. We dig in deep into the complexities of your planetary positions to provide targeted solutions that truly work
								</p>

							</Typography>

							<Typography
								variant="body2"
								textAlign="center"
								fontWeight="bold"
								sx={{
									fontFamily: `'Alatsi', sans-serif`, fontSize: '1.5rem',
									my: '50px', color: '#310033',
									textTransform: 'uppercase',
								}}
							>
								<p>
									WHAT YOU WILL GET IN YOUR REPORT:
								</p>
							</Typography>

						</Grid>
					</Grid>
				</Container>

				<Container sx={{ maxWidth: { xs: '100%', sm: '50%', md: '50%' } }}>
					<Grid
						container
						alignItems="flex-start" spacing={1}
						sx={{ display: 'flex', height: '100%' }}         >
						{/* Image Section */}
						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '35%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/landingPage8.png`}
								alt="Image Example"
								mt="20px"
								sx={{
									width: '100%',
									height: 'auto',
									borderRadius: '8px',
								}}
							/>
						</Grid>

						{/* Text Section */}
						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '60%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}
							>
								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>Your Professional Kundli:</span>
									<br />
									Want to unlock the secrets of your life? Our <span style={{ color: '#310033', fontWeight: 'bold' }}>Professional Kundli</span> gives you a detailed astrological blueprint. Discover hidden opportunities and insights with your personalised birth chart, planetary positions, and more.
								</p>
							</Typography>
						</Grid>

						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '35%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/landingPage9.png`}
								alt="Image Example"
								mt="20px"
								sx={{
									width: '100%',
									height: 'auto',
									borderRadius: '8px',
								}}
							/>
						</Grid>

						{/* Text Section */}
						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '60%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}
							>
								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										Your Life's Blueprint:
									</span>
									<br />
									Personalised Predictions for{' '}
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										Clarity and Growth
									</span>
									. Discover your unique potential with life predictions based on ancient Vedic wisdom. This personalised report analyses your Nakshatra, Yog, Karna, Rashi, and planetary placements to reveal
								</p>
							</Typography>
						</Grid>

						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '35%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/landingPage10.png`}
								alt="Image Example"
								mt="20px"
								sx={{
									width: '100%',
									height: 'auto',
									borderRadius: '8px',
								}}
							/>
						</Grid>

						{/* Text Section */}
						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '60%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}
							>
								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										Planet Aspects/ Hits:
									</span>
									<br />
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										Aspects are{' '}
									</span>
									the angular relationships (Positive/ Negative) between planets and Houses in your birth chart, revealing how these interactions influence different areas of your life.
								</p>
							</Typography>
						</Grid>

						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '35%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/landingPage11.png`}
								alt="Image Example"
								mt="20px"
								sx={{
									width: '100%',
									height: 'auto',
									borderRadius: '8px',
								}}
							/>
						</Grid>

						{/* Text Section */}
						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '60%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}
							>
								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										Negative Aspect Remedies:
									</span>
									<br />
									Get remedies to clear any negative aspect of planet on Money Planet or Money house in your Birth Chart to clear your{' '}
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										Money blockages
									</span>.

								</p>
							</Typography>
						</Grid>

						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '35%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/landingPage12.png`}
								alt="Image Example"
								mt="20px"
								sx={{
									width: '100%',
									height: 'auto',
									borderRadius: '8px',
								}}
							/>
						</Grid>

						{/* Text Section */}
						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '60%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}
							>
								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										Your Favourable Astro-Vastu Direction:
									</span>
									<br />
									Discover your auspicious directions to attract wealth, success, and abundance based on your Kundli. Transform your space,{' '}
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										transform your life
									</span>.

								</p>
							</Typography>
						</Grid>

						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '35%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/landingPage13.png`}
								alt="Image Example"
								mt="20px"
								sx={{
									width: '100%',
									height: 'auto',
									borderRadius: '8px',
								}}
							/>
						</Grid>

						{/* Text Section */}
						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '60%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}
							>
								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										Your Favourable Planet:
									</span>
									<br />
									Tap into the cosmic forces for financial gain. Our report reveals your lucky planets and provides actionable steps to{' '}
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										maximise their positive influence.
									</span>
								</p>
							</Typography>
						</Grid>

						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '35%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/landingPage14.png`}
								alt="Image Example"
								mt="20px"
								sx={{
									width: '100%',
									height: 'auto',
									borderRadius: '8px',
								}}
							/>
						</Grid>

						{/* Text Section */}
						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '60%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}
							>
								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										Vastu Remedies:
									</span>
									<br />
									Discover personalised remedies to optimise your home or office for abundance. Transform your space into a{' '}
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										magnet for Abundance
									</span>
									with our proven Vastu solutions.
								</p>
							</Typography>
						</Grid>

						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '35%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/landingPage15.png`}
								alt="Image Example"
								mt="20px"
								sx={{
									width: '100%',
									height: 'auto',
									borderRadius: '8px',
								}}
							/>
						</Grid>

						{/* Text Section */}
						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '60%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}
							>
								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										Astrology Remedies:
									</span>
									<br />
									Overcome planetary challenges and unlock your potential. Discover powerful remedies to reverse negative planetary influences and{' '}
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										manifest your desires.
									</span>

								</p>
							</Typography>
						</Grid>

						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '35%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/landingPage16.png`}
								alt="Image Example"
								mt="20px"
								sx={{
									width: '100%',
									height: 'auto',
									borderRadius: '8px',
								}}
							/>
						</Grid>

						{/* Text Section */}
						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '60%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}
							>
								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										Rahu and Ketu:
									</span>
									<br />
									Harness the Power of Rahu and Ketu. These shadow planets can amplify both positive and negative energies. You will get powerful remedies to{' '}
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										reduce their negative influence.
									</span>

								</p>
							</Typography>
						</Grid>

						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '35%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/landingPage17.png`}
								alt="Image Example"
								mt="20px"
								sx={{
									width: '100%',
									height: 'auto',
									borderRadius: '8px',
								}}
							/>
						</Grid>

						{/* Text Section */}
						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '60%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}
							>
								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										Mantra:
									</span>
									<br />
									Discover personalised mantras to harness the energy of your lucky planets, Rashi and manifest abundance. Experience the transformative power of vibration and witness a{' '}
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										positive shift in your life.
									</span>

								</p>
							</Typography>
						</Grid>

						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '35%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/landingPage18.png`}
								alt="Image Example"
								mt="20px"
								sx={{
									width: '100%',
									height: 'auto',
									borderRadius: '8px',
								}}
							/>
						</Grid>

						{/* Text Section */}
						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '60%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}
							>
								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										Report Delivery:
									</span>
									<br />
									The link to access the report is delivered via Email and Whatsapp message within 4 days of the order.
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										(6 Hours Expedite Delivery option available)
									</span>

								</p>
							</Typography>
						</Grid>

					</Grid>
				</Container>

				<Container
					sx={{
						maxWidth: { xs: '95%', sm: '50%', md: '50%' },
						border: '2px solid red',
						p: '10px',
						mt: '30px'
					}}
				>
					<Grid
						container
						alignItems="flex-start" spacing={2}
					>
						{/* Icon Section */}
						<Grid
							sx={{
								maxWidth: { xs: '10%', sm: '5%' },
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<p>
								<CancelIcon
									sx={{ fontSize: '1.5rem', color: 'red', mt: '6px' }}
								/>
							</p>
						</Grid>

						{/* Text Section */}
						<Grid
							sx={{
								maxWidth: { xs: '80%', sm: '90%' },
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
							}}
						>
							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}, m: 0
								}}             >
								<p><span style={{ color: 'red', fontWeight: 'bold' }}>
									Not a Get Rich Quick Scheme:{' '}
								</span>
									This transformative guide is not for procrastinators; this report equips you with powerful remedies and strategies, but it's your proactive application that unlocks their power. Sitting back and reading alone won't bring riches. Transform your life by taking action!
								</p>
								<p>
									We provide the tools, but your commitment and effort are the keys to achieving your goals and overcoming obstacles.
								</p>
							</Typography>

						</Grid>
					</Grid>
				</Container>

				<Container sx={{ maxWidth: { xs: '100%', sm: '50%', md: '50%' } }}>
					<Grid container alignItems="flex-start" justifyContent="center" spacing={2}>
						<Grid size={12}>

							<Typography
								variant="body2"
								textAlign="center"
								fontWeight="bold"
								sx={{
									fontFamily: `'Alatsi', sans-serif`, fontSize: '1.5rem',
									my: '50px',
									color: '#310033',
									textTransform: 'uppercase',
								}}
							>
								<p>
									ACT NOW AND GET YOUR FREE 4 LIFE CHANGING ASTRO - VASTU BONUSES WORTH ₹7,988/-
								</p>
							</Typography>
						</Grid>
					</Grid>

					<Grid
						container
						alignItems="flex-start" spacing={1}
						sx={{ display: 'flex', height: '100%' }}         >
						{/* Image Section */}
						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '40%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/bonus1.png`}
								alt="Image Example"
								sx={{
									width: '100%',
									height: 'auto',
									borderRadius: '8px',
								}}
							/>
						</Grid>

						{/* Text Section */}
						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '59%' }, display: 'flex', height: '100%' }}           >
							<Typography
								variant="body1"
								color="2c3d50"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}
							>
								<p>
									<span style={{ color: '#31004a', fontWeight: 'bold' }}>
										FREE BONUS 01
									</span>
									<br />
									<span style={{ fontWeight: 'bold' }}>
										Your Auspicious Seating Direction:{' '}
									</span>
									This direction enhances positivity, productivity, and overall well-being, creating a harmonious environment for success and prosperity.
								</p>

								<p>
									<span style={{ fontWeight: 'bold' }}>
										(Valued: ₹ 1,997/-)
									</span>
								</p>
							</Typography>
						</Grid>

						{/* Image Section */}
						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '40%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/bonus2.png`}
								alt="Image Example"
								mt="20px"
								sx={{
									width: '100%',
									height: 'auto',
									borderRadius: '8px',
								}}
							/>
						</Grid>

						{/* Text Section */}
						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '59%' }, display: 'flex', height: '100%' }}           >
							<Typography
								variant="body1"
								color="2c3d50"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}
							>
								<p>
									<span style={{ color: '#31004a', fontWeight: 'bold' }}>
										FREE BONUS 02
									</span>
									<br />
									<span style={{ fontWeight: 'bold' }}>
										Discover your perfect Rudraksha:{' '}
									</span>
									A Powerful sacred seed, Rudraksha beads hold powerful energies. This free bonus reveals your ideal Rudraksha for: Harmony, Empowerment, Wellbeing.
								</p>

								<p>
									<span style={{ fontWeight: 'bold' }}>
										(Valued: ₹ 1,997/-)
									</span>
								</p>
							</Typography>
						</Grid>

						{/* Image Section */}
						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '40%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/bonus3.png`}
								alt="Image Example"
								mt="20px"
								sx={{
									width: '100%',
									height: 'auto',
									borderRadius: '8px',
								}}
							/>
						</Grid>

						{/* Text Section */}
						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '59%' }, display: 'flex', height: '100%' }}           >
							<Typography
								variant="body1"
								color="2c3d50"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}
							>
								<p>
									<span style={{ color: '#31004a', fontWeight: 'bold' }}>
										FREE BONUS 03
									</span>
									<br />
									<span style={{ fontWeight: 'bold' }}>
										Gemstone Suggestions for Money:{' '}
									</span>
									This can help enhance financial prospects and attract prosperity, providing stability and abundance in life.
								</p>

								<p>
									<span style={{ fontWeight: 'bold' }}>
										(Valued: ₹ 1,997/-)
									</span>
								</p>
							</Typography>
						</Grid>

						{/* Image Section */}
						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '40%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/bonus4.png`}
								alt="Image Example"
								mt="20px"
								sx={{
									width: '100%',
									height: 'auto',
									borderRadius: '8px',
								}}
							/>
						</Grid>

						{/* Text Section */}
						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '59%' }, display: 'flex', height: '100%' }}           >
							<Typography
								variant="body1"
								color="2c3d50"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}
							>
								<p>
									<span style={{ color: '#31004a', fontWeight: 'bold' }}>
										FREE BONUS 04
									</span>
									<br />
									<span style={{ fontWeight: 'bold' }}>
										Favourable Color Suggestion:{' '}
									</span>
									Colors impact emotions, energy, and planetary connections. This report reveals your lucky color based on your birth chart's planetary influences.
								</p>

								<p>
									<span style={{ fontWeight: 'bold' }}>
										(Valued: ₹ 1,997/-)
									</span>
								</p>
							</Typography>
						</Grid>

						{/* Image Section */}
						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '40%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/bonus5.png`}
								alt="Image Example"
								mt="20px"
								sx={{
									width: '100%',
									height: 'auto',
									borderRadius: '8px',
								}}
							/>
						</Grid>

						{/* Text Section */}
						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '59%' }, display: 'flex', height: '100%' }}           >
							<Typography
								variant="body1"
								color="2c3d50"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}
							>
								<p>
									<span style={{ color: '#31004a', fontWeight: 'bold' }}>
										FREE BONUS 05
									</span>
									<br />
									<span style={{ fontWeight: 'bold' }}>
										Essential Donation{' '}
									</span>
									you should do, Donation is believed to balance karma and appease planetary influences, promoting harmony and positive energies in your life's journey.
								</p>

								<p>
									<span style={{ fontWeight: 'bold' }}>
										(Valued: ₹ 997/-)
									</span>
								</p>
							</Typography>
						</Grid>

					</Grid>

					<Grid container alignItems="flex-start" justifyContent="center" spacing={2}>
						<Grid size={12}>

							<Typography
								variant="body2"
								textAlign="center"
								fontWeight="bold"
								sx={{
									fontFamily: `'Alatsi', sans-serif`, fontSize: '1.5rem',
									my: '50px',
									color: '#310033',
									textTransform: 'uppercase',
								}}
							>
								<p>
									ACT NOW AND ALSO GET
								</p>
							</Typography>
						</Grid>
					</Grid>

					<Grid
						container
						alignItems="flex-start" spacing={1}
						sx={{ display: 'flex', height: '100%' }}         >
						{/* Image Section */}
						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '40%' }, display: 'flex', alignItems: 'center', height: '100%' }}           >
							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/landingPage19.png`}
								alt="Image Example"
								sx={{
									width: '100%',
									height: 'auto',
									borderRadius: '8px',
								}}
							/>
						</Grid>

						{/* Text Section */}
						<Grid
							sx={{ maxWidth: { xs: '100%', sm: '59%' }, display: 'flex', height: '100%' }}           >
							<Typography
								variant="body1"
								color="2c3d50"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}
							>
								<p>
									<span style={{ color: '#31004a', fontWeight: 'bold' }}>
										Vastu and Astrology Course
									</span>
									<br />
									<span style={{ fontWeight: 'bold' }}>
										Join Acharya Rajhkumar Singh's{' '}
									</span>
									exclusive Vastu and Astrology course and gain the power to create positive change.
								</p>

								<p>
									<span style={{ fontWeight: 'bold' }}>
										(Valued: ₹ 9,997/-)
									</span>
								</p>
							</Typography>
						</Grid>
					</Grid>

					<Grid container alignItems="flex-start" justifyContent="center" spacing={2}>
						<Grid size={12}>

							<Typography
								variant="body2"
								textAlign="center"
								fontWeight="bold"
								sx={{
									fontFamily: `'Alatsi', sans-serif`, fontSize: '1.5rem',
									my: '50px',
									color: '#310033',
									textTransform: 'uppercase',
								}}
							>
								<p>
									WHO CAN BENEFIT FROM THIS REPORT?
								</p>
							</Typography>

							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}, marginTop: '5px'
								}}             >
								<p>
									Whether you're;
								</p>

								<p>
									✅ an entrepreneur chasing a dream,
									<br />
									✅ a busy professional juggling work and life,
									<br />
									✅ a student yearning for a brighter future,
									<br />
									✅ or a rockstar homemaker holding the family together,
								</p>

								<p>
									Our exclusive Astro-Vastu report is tailored for those seeking increased wealth and abundance.
								</p>

								<p>
									Whether you aim to enhance your financial standing or simply aspire for greater success, this report gives you personalized advice based on Astrology and Vastu.
								</p>

								<p>
									It's all about using cosmic energies to bring prosperity your way. Get ready to open the doors to more wealth and abundance!
								</p>
							</Typography>

							<Typography
								variant="body2"
								textAlign="center"
								fontWeight="bold"
								sx={{
									fontFamily: `'Alatsi', sans-serif`, fontSize: '1.5rem',
									my: '50px',
									color: '#310033',
									textTransform: 'uppercase',
								}}
							>
								<p>
									HOW IT WORKS?
								</p>
							</Typography>

							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}, marginTop: '5px'
								}}             >
								<p>
									Getting started is simple.
								</p>

								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>1. Share Your Birth Details:</span>{' '}
									Provide your birth information (Date, Time and Place).
								</p>

								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>2. Chart Preparation:</span>{' '}
									Our Team will create your birth chart based on the details provided.
								</p>

								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>3. Thorough Analysis:</span>{' '}
									We'll analyze your birth chart and suggest Astrology and Vastu remedies for More Money, Success, and Happiness.
								</p>

								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>4. Prompt Report Delivery:</span>{' '}
									Receive your detailed report within just 4 days on our portal, with login details sent via WhatsApp and Email. <i style={{ color: '#310033' }}>(6 Hours Expedite Delivery option available)</i>
								</p>

							</Typography>

							<Typography
								variant="body2"
								textAlign="center"
								fontWeight="bold"
								sx={{
									fontFamily: `'Alatsi', sans-serif`, fontSize: '1.5rem',
									my: '50px',
									color: '#310033',
									textTransform: 'uppercase',
								}}
							>
								<p>
									DON’T MISS THIS LIMITED TIME OFFER TO TRANSFORM YOUR LIFE
								</p>
							</Typography>

							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}, marginTop: '5px'
								}}             >
								<p>
									Rooted in ancient wisdom, Your Abundance Blueprint Report provides simple yet powerful remedies to actively shape your destiny and attract opportunities. Imagine the universe working to bring your goals to life. This is the transformative power of Astro-Vastu!
								</p>

								<p>
									The best time to plant the seed of success is NOW.
								</p>

								<p>
									Don't wait. Explore Astro-Vastu remedies today and watch the universe turn your dreams into reality.
								</p>
							</Typography>

							<Typography
								variant="body2"
								textAlign="center"
								fontWeight="bold"
								sx={{
									fontFamily: `'Alatsi', sans-serif`, fontSize: '1.6rem',
									my: '50px', color: '#310033',
									textTransform: 'uppercase',
								}}
							>
								<p>
									Here's an UNBEATABLE OFFER
								</p>
							</Typography>

							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/landingPage20.png`}
								alt="Life-Changing Bonuses"
								sx={{
									width: '100%', height: 'auto',
									marginTop: '30px',
								}}
							/>

							<Typography
								variant="body1"
								color="black"
								textAlign="center"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}, marginTop: '20px'
								}}             >
								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										Incredible 86% Off!
									</span>.
								</p>

								<p>
									Regular Price:<span style={{ textDecoration: 'line-through' }}>₹5,999/-</span>
									<br />
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										Your Price Today: Just ₹799/-
									</span>
									<br />
									(Less than a Large Pizza!)
									<br />
									+
									<br />
									You get FREE 5 Life Changing Bonus worth ₹8,985/-
									<br />
									+
									<br />
									Access to Vastu and Astrology Course Worth ₹9,999/-,
								</p>

								<p>
									Total benefit over{' '}
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										₹24,184/-
									</span>
								</p>

								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										Don't wait!{' '}
									</span>
									Claim your Abundance Blueprint Report and these exclusive bonuses NOW!
								</p>

								<p style={{ marginBottom: '40px' }}>
									1000's have already benefitted from this report
								</p>

								{/* COUNT DOWN HERE */}
								<CountdownTimer initialMinutes={30} />


								<p style={{ marginTop: '30px' }}>
									⏱️ Time is Ticking
									<br />
									Special Offer Ends Soon!
								</p>

							</Typography>

							<Box
								display="flex" justifyContent="center"
							>
								<Box

									component="img"
									src={`${process.env.PUBLIC_URL}/Images/down.png`}
									alt="Life-Changing Bonuses"
									sx={{
										width: '25%', height: 'auto',
										marginTop: '10px',
									}}
								/>
							</Box>

							<Link to={linkToNewUserPurchaseComponent}>
								<Button
									variant="contained"
									sx={{
										width: '100%',
										p: '12px',
										fontSize: {
											xs: '1rem',
											sm: '1.2rem',
											md: '1.2rem'
										},
										backgroundColor: '#f1c40f',
										color: 'black',
										borderRadius: '50px',
										border: '2px solid black',
										textTransform: 'none',
										'&:hover': {
											backgroundColor: '#d4ac0d',
										},
									}}
								>
									Order Your Report Now!
								</Button>
							</Link>

							<Typography
								variant="body1"
								color="black"
								textAlign="center"
								sx={{
									fontSize: {
										xs: '0.7rem',
										sm: '0.9rem',
										md: '0.9rem'
									}, mt: '10px'
								}}
							>
								3700+ Report Delivered
								<br />
								Start Manifesting Your Dreams Today!
								<br />
								Use{' '}
								<span style={{ fontWeight: 'bold' }}>
									GET100
								</span>{' '}
								for ₹100/- OFF
							</Typography>

						</Grid>
					</Grid>
				</Container>

			</Box>

			<Box
				sx={{
					width: '100%',
					backgroundColor: '#310033',
					display: 'flex',
					flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', py: '50px',
				}}
			>


				<Container sx={{ maxWidth: { xs: '100%', sm: '70%', md: '70%' } }}>
					<Grid container alignItems="flex-start" justifyContent="center" spacing={2}>
						<Grid
							container
							alignItems="center" spacing={5}
							sx={{ display: 'flex', height: '100%' }}           >
							{/* Image Section */}
							<Grid
								sx={{ maxWidth: { xs: '100%', sm: '45%' }, display: 'flex', alignItems: 'center', height: '100%' }}             >
								<Box
									component="img"
									src={`${process.env.PUBLIC_URL}/Images/landingPage21.gif`}
									alt="Image Example"
									sx={{
										width: '100%',
										height: 'auto',
									}}
								/>
							</Grid>

							{/* Text Section */}
							<Grid
								sx={{ maxWidth: { xs: '100%', sm: '45%' }, display: 'flex', alignItems: 'center', height: '100%' }}             >
								<Grid>
									<Typography
										variant="body1"
										color="white"
										textAlign="left"
										alignItems="center" sx={{
											fontSize: {
												xs: '1rem',
												sm: '1.2rem',
												md: '1.2rem'
											}
										}}
									>
										<p>
											Your Abundance Blueprint Report combines the power of Vedic Astrology and Vastu Shastra to create a personalized roadmap to financial prosperity and wish fulfillment.
										</p>

										<p>
											We analyze your unique birth chart to identify any potential money blocks and create customized Astro-Vastu solutions to help you overcome them.
										</p>
									</Typography>

									<Typography
										variant="body1"
										color="white"
										textAlign="center"
										sx={{
											fontSize: {
												xs: '0.7rem',
												sm: '0.9rem',
												md: '0.9rem'
											}, mt: '20px'
										}}
									>
										Regular Price: <span style={{ textDecoration: 'line-through' }}>₹5,999/-</span> | Save 86%
									</Typography>

									<Link to={linkToNewUserPurchaseComponent}>
										<Button
											variant="contained"
											sx={{
												width: '100%',
												mt: '10px',
												p: '12px',
												fontSize: {
													xs: '1rem',
													sm: '1.2rem',
													md: '1.2rem'
												},
												backgroundColor: '#f1c40f',
												color: 'black',
												borderRadius: '50px',
												border: '2px solid white',
												textTransform: 'none',
												'&:hover': {
													backgroundColor: '#d4ac0d',
												},
											}}
										>
											Act Fast: ₹799 for Your Report
										</Button>
									</Link>

									<Typography
										variant="body1"
										color="white"
										textAlign="center"
										mt="10px"
										sx={{
											fontSize: {
												xs: '0.7rem',
												sm: '0.9rem',
												md: '0.9rem'
											}
										}}
									>
										3700+ Report Delivered
										<br />
										Start Manifesting Your Dreams Today!
										<br />
										Use{' '}
										<span style={{ color: '#f4eb03', fontWeight: 'bold' }}>
											GET100
										</span>{' '}
										for ₹100/- OFF
									</Typography>

								</Grid>

							</Grid>

						</Grid>
					</Grid>
				</Container>

			</Box>

			<Box
				sx={{
					width: '100%',
					backgroundColor: 'white',
					display: 'flex',
					flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', py: '50px',
				}}
			>
				<Container sx={{ maxWidth: { xs: '100%', sm: '50%', md: '50%' } }}>
					<Grid container alignItems="flex-start" justifyContent="center" spacing={2}>
						<Grid size={12}>
							<Typography
								variant="body1"
								color="#310033"
								textAlign="center"
								sx={{ fontSize: '1.1rem' }}             >
								Need Help Deciding?
							</Typography>

							<Typography
								variant="body2"
								textAlign="center"
								fontWeight="bold"
								sx={{
									fontFamily: `'Alatsi', sans-serif`, fontSize: '1.5rem',
									mt: '10px', color: '#192734',
									textTransform: 'uppercase',
								}}
							>
								<p>
									Words from Our Delighted Clients:
								</p>
							</Typography>

							<Typography
								variant="body1"
								color="#2c3d50"
								textAlign="center"
								sx={{
									fontSize: {
										xs: '0.7rem',
										sm: '0.9rem',
										md: '0.9rem'
									}
								}}
							>
								Experiences That Speak for Themselves
							</Typography>

							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}, marginTop: '20px'
								}}             >
								<p style={{ marginTop: '30px' }}>
									"The 'Your Abundance Blueprint Report' was even better than I thought it would be.
								</p>
								<p>
									It gave me amazing ideas and steps to get lots of good things in life. I feel really lucky to have found this report, and I think everyone should try it because it can change your life for the better."
								</p>

							</Typography>

							<Box
								display="flex" justifyContent="center"
							>
								<Box

									component="img"
									src={`${process.env.PUBLIC_URL}/Images/star.png`}
									alt="Life-Changing Bonuses"
									sx={{
										width: {
											xs: '30%',
											sm: '16%',
											md: '16%',
										}, height: 'auto',
										marginTop: '10px',
									}}
								/>

							</Box>

							<Box
								display="flex" justifyContent="center"
							>
								<Box
									component="img"
									src={`${process.env.PUBLIC_URL}/Images/review7.png`}
									alt="Life-Changing Bonuses"
									sx={{
										width: {
											xs: '30%',
											sm: '16%',
											md: '16%',
										}, height: 'auto',
										marginTop: '20px',
									}}
								/>
							</Box>

							<Typography
								variant="body1"
								color="black"
								textAlign="center"
								borderBottom="1px dotted black"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}, marginTop: '20px'
								}}             >
								<p style={{ marginTop: '10px' }}>
									Mitesh Gajjar, Consultant
								</p>
							</Typography>

							<Typography
								variant="body1"
								color="black"
								textAlign="center"
								sx={{
									fontSize: {
										xs: '0.7rem',
										sm: '0.9rem',
										md: '0.9rem'
									}, marginTop: '10px'
								}}
							>
								<Link
									to={linkToNewUserPurchaseComponent}
									rel="noopener noreferrer"
									style={{
										color: '#178bf6', textDecoration: 'none',
									}}
									onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
									onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
								>
									{'>>'} Grab Your Report Now {'<<'}
								</Link>
							</Typography>


						</Grid>
					</Grid>
				</Container>
			</Box>

			<Box
				sx={{
					width: '100%',
					backgroundColor: 'black',
					display: 'flex',
					flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', py: '50px',
				}}
			>
				<Container sx={{ maxWidth: { xs: '100%', sm: '50%', md: '50%' } }}>
					<Grid container alignItems="flex-start" justifyContent="center" spacing={2}>
						<Grid size={12}>

							<Typography
								variant="body1"
								color="white"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}
							>
								<p>
									"I ordered Your Abundance Blueprint out of curiosity. However, I was pleasantly surprised by the accuracy of the predictions. The report accurately highlighted certain challenges I was facing and suggested remedies that worked wonders.
								</p>
								<p>
									It turned out to be one of the best decisions I've made. I would recommend it."
								</p>

							</Typography>

							<Box
								display="flex" justifyContent="center"
							>
								<Box

									component="img"
									src={`${process.env.PUBLIC_URL}/Images/star.png`}
									alt="Life-Changing Bonuses"
									sx={{
										width: {
											xs: '30%',
											sm: '16%',
											md: '16%',
										}, height: 'auto',
										marginTop: '10px',
									}}
								/>

							</Box>

							<Box
								display="flex" justifyContent="center"
							>
								<Box

									component="img"
									src={`${process.env.PUBLIC_URL}/Images/review2.png`}
									alt="Life-Changing Bonuses"
									sx={{
										width: {
											xs: '30%',
											sm: '16%',
											md: '16%',
										}, height: 'auto',
										marginTop: '20px',
									}}
								/>
							</Box>

							<Typography
								variant="body1"
								color="white"
								textAlign="center"
								borderBottom="1px dotted white"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}, marginTop: '20px'
								}}             >
								<p style={{ marginTop: '10px' }}>
									Dr. Hemlata Joel, Doctor - Kandivili.
								</p>
							</Typography>

							<Typography
								variant="body1"
								color="red"
								textAlign="center"
								sx={{
									fontSize: {
										xs: '0.7rem',
										sm: '0.9rem',
										md: '0.9rem'
									}, marginTop: '10px'
								}}             >
								<Link
									to={linkToNewUserPurchaseComponent}
									rel="noopener noreferrer"
									style={{
										color: '#f8d371', textDecoration: 'none',
									}}
									onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
									onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
								>
									{'>>'} Grab Your Report Now {'<<'}
								</Link>
							</Typography>


						</Grid>
					</Grid>
				</Container>
			</Box>

			<Box
				sx={{
					width: '100%',
					backgroundColor: 'white',
					display: 'flex',
					flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', py: '50px',
				}}
			>
				<Container sx={{ maxWidth: { xs: '100%', sm: '50%', md: '50%' } }}>
					<Grid container alignItems="flex-start" justifyContent="center" spacing={2}>
						<Grid size={12}>

							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}             >
								<p >
									"The Abundance Blueprint Report changed how I see money. Thanks to Acharya Rajhkumar Singh's advice, I now understand my financial challenges better and know how to overcome them.
								</p>
								<p>
									I now feel more confident. Don't miss out on this amazing opportunity."
								</p>

							</Typography>

							<Box
								display="flex" justifyContent="center"
							>
								<Box

									component="img"
									src={`${process.env.PUBLIC_URL}/Images/star.png`}
									alt="Life-Changing Bonuses"
									sx={{
										width: {
											xs: '30%',
											sm: '16%',
											md: '16%',
										}, height: 'auto',
										marginTop: '10px',
									}}
								/>

							</Box>

							<Box
								display="flex" justifyContent="center"
							>
								<Box

									component="img"
									src={`${process.env.PUBLIC_URL}/Images/review3.png`}
									alt="Life-Changing Bonuses"
									sx={{
										width: {
											xs: '30%',
											sm: '16%',
											md: '16%',
										}, height: 'auto',
										marginTop: '20px',
									}}
								/>
							</Box>

							<Typography
								variant="body1"
								color="black"
								textAlign="center"
								borderBottom="1px dotted black"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}, marginTop: '20px'
								}}             >
								<p style={{ marginTop: '10px' }}>
									Sonu Agarwal, Owner Chokri Ki Bakery, Siliguri
								</p>
							</Typography>

							<Typography
								variant="body1"
								textAlign="center"
								sx={{
									fontSize: {
										xs: '0.7rem',
										sm: '0.9rem',
										md: '0.9rem'
									}, marginTop: '10px'
								}}             >
								<Link
									to={linkToNewUserPurchaseComponent}
									rel="noopener noreferrer"
									style={{
										color: '#178bf6', textDecoration: 'none',
									}}
									onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
									onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
								>
									{'>>'} Grab Your Report Now {'<<'}
								</Link>
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</Box>

			<Box
				sx={{
					width: '100%',
					backgroundColor: 'black',
					display: 'flex',
					flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', py: '50px',
				}}
			>
				<Container sx={{ maxWidth: { xs: '100%', sm: '50%', md: '50%' } }}>
					<Grid container alignItems="flex-start" justifyContent="center" spacing={2}>
						<Grid size={12}>

							<Typography
								variant="body1"
								color="white"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}
							>
								<p >
									"Your Abundance Blueprint Report's predictions were incredibly accurate, and the remedies were so easy to apply. I've gained clarity and confidence after applying the remedies suggested n the report.
								</p>
								<p>
									My financial situation has improved significantly. Don't think twice about buying this report!"
								</p>

							</Typography>

							<Box
								display="flex" justifyContent="center"
							>
								<Box

									component="img"
									src={`${process.env.PUBLIC_URL}/Images/star.png`}
									alt="Life-Changing Bonuses"
									sx={{
										width: {
											xs: '30%',
											sm: '16%',
											md: '16%',
										}, height: 'auto',
										marginTop: '10px',
									}}
								/>

							</Box>

							<Box
								display="flex" justifyContent="center"
							>
								<Box

									component="img"
									src={`${process.env.PUBLIC_URL}/Images/review4.png`}
									alt="Life-Changing Bonuses"
									sx={{
										width: {
											xs: '30%',
											sm: '16%',
											md: '16%',
										}, height: 'auto',
										marginTop: '20px',
									}}
								/>
							</Box>

							<Typography
								variant="body1"
								color="white"
								textAlign="center"
								borderBottom="1px dotted white"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}, marginTop: '20px'
								}}             >
								<p style={{ marginTop: '10px' }}>
									Shanjan Taneja, Heal Astro Vastu - New Delhi
								</p>
							</Typography>

							<Typography
								variant="body1"
								textAlign="center"
								sx={{
									fontSize: {
										xs: '0.7rem',
										sm: '0.9rem',
										md: '0.9rem'
									}, marginTop: '10px'
								}}             >
								<Link
									to={linkToNewUserPurchaseComponent}
									rel="noopener noreferrer"
									style={{
										color: '#f8d371', textDecoration: 'none',
									}}
									onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
									onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
								>
									{'>>'} Grab Your Report Now {'<<'}
								</Link>
							</Typography>


						</Grid>
					</Grid>
				</Container>
			</Box>

			<Box
				sx={{
					width: '100%',
					backgroundColor: 'white',
					display: 'flex',
					flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', py: '50px',
				}}
			>
				<Container sx={{ maxWidth: { xs: '100%', sm: '50%', md: '50%' } }}>
					<Grid container alignItems="flex-start" justifyContent="center" spacing={2}>
						<Grid size={12}>

							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}             >
								<p >
									"Your Abundance Blueprint Report's Vastu solutions were just what I needed!
								</p>
								<p>
									Acharya Rajhkumar Singh's remedies were practical and straightforward, and they made a world of difference in my finances. I highly recommend this report to everyone!"
								</p>

							</Typography>

							<Box
								display="flex" justifyContent="center"
							>
								<Box

									component="img"
									src={`${process.env.PUBLIC_URL}/Images/star.png`}
									alt="Life-Changing Bonuses"
									sx={{
										width: {
											xs: '30%',
											sm: '16%',
											md: '16%',
										}, height: 'auto',
										marginTop: '10px',
									}}
								/>

							</Box>

							<Box
								display="flex" justifyContent="center"
							>
								<Box

									component="img"
									src={`${process.env.PUBLIC_URL}/Images/review5.png`}
									alt="Life-Changing Bonuses"
									sx={{
										width: {
											xs: '30%',
											sm: '16%',
											md: '16%',
										}, height: 'auto',
										marginTop: '20px',
									}}
								/>
							</Box>

							<Typography
								variant="body1"
								color="black"
								textAlign="center"
								borderBottom="1px dotted black"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}, marginTop: '20px'
								}}             >
								<p style={{ marginTop: '10px' }}>
									Priya Ahuja, Yoga Trainer - Mumbai
								</p>
							</Typography>

							<Typography
								variant="body1"
								textAlign="center"
								sx={{
									fontSize: {
										xs: '0.7rem',
										sm: '0.9rem',
										md: '0.9rem'
									}, marginTop: '10px'
								}}             >
								<Link
									to={linkToNewUserPurchaseComponent}
									rel="noopener noreferrer"
									style={{
										color: '#178bf6', textDecoration: 'none',
									}}
									onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
									onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
								>
									{'>>'} Grab Your Report Now {'<<'}
								</Link>
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</Box>

			<Box
				sx={{
					width: '100%',
					backgroundColor: 'black',
					display: 'flex',
					flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', py: '50px',
				}}
			>
				<Container sx={{ maxWidth: { xs: '100%', sm: '50%', md: '50%' } }}>
					<Grid container alignItems="flex-start" justifyContent="center" spacing={2}>
						<Grid size={12}>

							<Typography
								variant="body1"
								color="white"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}             >
								<p >
									"I cannot express enough gratitude for the transformative insights provided by the 'Your Abundance Blueprint Report.' It's like unlocking the hidden treasures of the universe tailored specifically to me.
								</p>
								<p>
									This report is not just a document; it's a roadmap to manifesting prosperity and fulfillment. I wholeheartedly recommend it to anyone seeking to unlock their true potential and embrace abundance in all aspects of life."
								</p>

							</Typography>

							<Box
								display="flex" justifyContent="center"
							>
								<Box

									component="img"
									src={`${process.env.PUBLIC_URL}/Images/star.png`}
									alt="Life-Changing Bonuses"
									sx={{
										width: {
											xs: '30%',
											sm: '16%',
											md: '16%',
										}, height: 'auto',
										marginTop: '10px',
									}}
								/>

							</Box>

							<Box
								display="flex" justifyContent="center"
							>
								<Box

									component="img"
									src={`${process.env.PUBLIC_URL}/Images/review6.png`}
									alt="Life-Changing Bonuses"
									sx={{
										width: {
											xs: '30%',
											sm: '16%',
											md: '16%',
										}, height: 'auto',
										marginTop: '20px',
									}}
								/>
							</Box>

							<Typography
								variant="body1"
								color="white"
								textAlign="center"
								borderBottom="1px dotted white"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}, marginTop: '20px'
								}}             >
								<p style={{ marginTop: '10px' }}>
									Santosh Narvekar, Philanthropist - Thane
								</p>
							</Typography>

							<Typography
								variant="body1"
								color="red"
								textAlign="center"
								sx={{
									fontSize: {
										xs: '0.7rem',
										sm: '0.9rem',
										md: '0.9rem'
									}, marginTop: '10px'
								}}             >
								<Link
									to={linkToNewUserPurchaseComponent}
									rel="noopener noreferrer"
									style={{
										color: '#f8d371', textDecoration: 'none',
									}}
									onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
									onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
								>
									{'>>'} Grab Your Report Now {'<<'}
								</Link>
							</Typography>


						</Grid>
					</Grid>
				</Container>
			</Box>

			<Box
				sx={{
					width: '100%',
					backgroundColor: 'white',
					display: 'flex',
					flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', paddingY: '50px',
				}}
			>
				<Container sx={{ maxWidth: { xs: '100%', sm: '50%', md: '50%' } }}>
					<Grid container alignItems="flex-start" justifyContent="center" spacing={2}>
						<Grid size={12}>

							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/landingPage22.png`}
								alt="Life-Changing Bonuses"
								sx={{
									width: '100%', height: 'auto',
								}}
							/>

							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/landingPage23.png`}
								alt="Life-Changing Bonuses"
								sx={{
									width: '100%', height: 'auto',
									marginTop: '15px',
								}}
							/>

							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}, marginTop: '10px'
								}}             >
								<p>
									After surviving the 2006 Mumbai train terror bombings, I found myself struggling with profound questions about life's purpose and meaning, seeking help and answers during physical and emotional turmoil.
								</p>

								<p>
									In my journey toward healing, I encountered the transformative influence of Vastu Shastra in 2012. Witnessing remarkable improvements in my personal and professional life after implementing Vastu corrections in my own home, I researched deeper into this ancient Indian Science.
								</p>

								<p>
									I'm Acharya Rajhkumar Singh, an expert in Vastu Shastra and Astrology, with over 9 years of dedicated experience in these mystic sciences. Trusted by clients worldwide, my expertise has earned an excellent Trustpilot rating of 4.8 out of 5.
								</p>

								<p>
									Through my practice, I've assisted numerous individuals, professionals, and businesses worldwide, offering tailored Vastu and Astrology solutions that accelerate growth and attract success.
								</p>

								<p>
									Alongside guiding others toward harmony and success, I find joy in witnessing the positive transformations facilitated by Vastu and Astrology, making the mystical journey an exciting and fulfilling endeavour.
								</p>
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</Box>

			<Box
				sx={{
					width: '100%',
					backgroundColor: '#141414',
					display: 'flex',
					flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', py: '50px',
				}}
			>
				<Container sx={{ maxWidth: { xs: '100%', sm: '50%', md: '50%' } }}>
					<Grid container alignItems="flex-start" justifyContent="center" spacing={2}>
						<Grid size={12}>

							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/landingPage24.gif`}
								alt="Life-Changing Bonuses"
								sx={{
									width: '100%', height: 'auto',
								}}
							/>

							<Typography
								variant="body2"
								textAlign="center"
								fontWeight="bold"
								sx={{
									fontFamily: `'Alatsi', sans-serif`, fontSize: {
										xs: '1.7rem',
										sm: '1.8rem',
										md: '1.8rem'
									},
									my: '50px', color: '#f4eb00',
									textTransform: 'uppercase',
								}}
							>
								<p>
									This transformative guide is not for procrastinators;
								</p>

							</Typography>

							<Typography
								variant="body1"
								color="white"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}, marginTop: '10px'
								}}             >
								<p>
									The Abundance Blueprint Report is exclusively crafted for individuals who are ready to take decisive action and who have a genuine desire to unlock their full potential and embrace a life of abundance, success, and happiness.
								</p>
								<p>
									If you're someone with a strong will to shape your destiny, then the Abundance Blueprint Report is your personalized roadmap to manifesting your dreams.
								</p>
							</Typography>

							<Typography
								variant="body2"
								textAlign="center"
								fontWeight="bold"
								sx={{
									fontFamily: `'Alatsi', sans-serif`, fontSize: '1.5rem',
									my: '50px', color: '#f4eb00',
									textTransform: 'uppercase',
								}}
							>
								<p>
									Here's an UNBEATABLE OFFER
								</p>
							</Typography>

							<Typography
								variant="body1"
								color="white"
								textAlign="center"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}, marginTop: '50px'
								}}             >
								<p style={{ color: '#f4eb00', fontWeight: 'bold' }}>
									Incredible 86% Off!
								</p>
								<p>
									Regular Price:{' '}
									<span style={{ textDecoration: 'line-through' }}>
										₹5,999/-
									</span>
									<br />
									<span style={{ color: '#f4eb00', fontWeight: 'bold' }}>
										Your Price Today: Just ₹799/-
									</span>
									<br />
									(Less than a Large Pizza!)
									<br />
									+
									<br />
									You get FREE
									<br />
									5 Life Changing Bonus worth ₹8,985/-
									<br />
									+
									<br />
									Access to Vastu and Astrology Course Worth ₹9,999/-,
								</p>

								<p>
									Total benefit over{' '}
									<span style={{ color: '#f4eb00', fontWeight: 'bold' }}>
										₹24,184/-
									</span>
								</p>

								<p>
									Don't wait!
									<br />
									Claim your Abundance Blueprint Report and these exclusive bonuses NOW!
								</p>

								<p>
									1000's have already benefitted from this report
								</p>

								<CountdownTimer initialMinutes={30} />


								<p style={{ marginTop: '30px' }}>
									⏱️ Time is Ticking
									<br />
									Special Offer Ends Soon!
								</p>

								<Box
									display="flex" justifyContent="center"
								>
									<Box

										component="img"
										src={`${process.env.PUBLIC_URL}/Images/down2.png`}
										alt="Life-Changing Bonuses"
										sx={{
											width: '25%', height: 'auto',
											marginTop: '10px',
										}}
									/>
								</Box>

								<Link to={linkToNewUserPurchaseComponent}>
									<Button
										variant="contained"
										sx={{
											width: '100%',
											p: '18px',
											mb: '10px',
											fontSize: {
												xs: '1rem',
												sm: '1.2rem',
												md: '1.2rem'
											},
											fontWeight: 'bold',
											backgroundColor: '#f8d371',
											color: 'black',
											borderRadius: '50px',
											border: '2px solid white',
											textTransform: 'none',
											'&:hover': {
												backgroundColor: '#d4ac0d',
											},
										}}
									>
										Decode Your Destiny Now
									</Button>
								</Link>

								<Box sx={{
									color: 'white',
									textDecoration: 'none',
									fontSize: {
										xs: '0.7rem',
										sm: '0.9rem',
										md: '0.9rem',
									}
								}}>
									<Link
										to={linkToNewUserPurchaseComponent}
										rel="noopener noreferrer"
										style={{
											color: 'white', textDecoration: 'none'
										}}
										onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
										onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
									>
										{'>>'} Join the abundance journey like others! {'<<'}
									</Link>
								</Box>


							</Typography>


						</Grid>
					</Grid>
				</Container>
			</Box>

			<Box
				sx={{
					width: '100%',
					backgroundColor: '#f8f9fa',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					alignItems: 'center',
					py: '50px'
				}}
			>
				<AccordionComponent /> {/* Render the AccordionComponent */}
			</Box>

			<Box
				sx={{
					width: '100%',
					backgroundColor: 'white',
					display: 'flex',
					flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', py: '50px',
				}}
			>
				<Container sx={{ maxWidth: { xs: '100%', sm: '50%', md: '50%' } }}>
					<Grid container alignItems="flex-start" justifyContent="center" spacing={2}>
						<Grid size={12}>

							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}             >

								<p>
									Dear Reader,
								</p>

								<p>
									Are you seeking a transformative journey towards abundance and success in your life? Look no further, as I present to you{' '}
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										"Your Abundance Blueprint - Your personalized Astro-Vastu guide for growth and success."
									</span>
								</p>

								<p>
									Imagine a life where opportunities flow effortlessly, where abundance becomes your daily reality, and where success becomes second nature. This unique and powerful guide is designed to unlock the hidden potential within you and align your life with the positive energies of the universe.
								</p>

								<p>
									With my expertise in Vastu Shastra and Astrology, I have helped countless individuals, just like you, to overcome challenges, embrace positivity, and achieve their deepest desires. Now, I am excited to share these potent remedies and practical solutions with you.
								</p>

								<p>
									Through{' '}
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										"Your Abundance Blueprint - Your personalized Astro-Vastu guide for growth and success", you will experience:
									</span>
								</p>

								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										1. Enhanced Prosperity:
									</span>
									Discover how the alignment of energies can attract wealth and financial growth into your life.
								</p>

								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										2. Harmonious Relationships:
									</span>
									Unlock the secrets of Vastu Shastra and Astrology to nurture loving and fulfilling relationships with family, friends, and partners.
								</p>

								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										3. Improved Health and Wellness:
									</span>
									Experience the positive impact of Vastu and Astrology on your well-being, leading to a healthier and happier life.
								</p>

								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										4. Professional Success:
									</span>
									Align your workspace with positive energies to boost productivity and accelerate your career growth.
								</p>

								<p>
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										5. Inner Fulfilment:
									</span>
									Embrace the power of cosmic energies to find peace, balance, and purpose in your life's journey.
								</p>

								<p>
									Don't let opportunities slip away or allow obstacles to hold you back any longer. "Your Abundance Blueprint" is your gateway to an extraordinary life filled with prosperity and joy.
								</p>

								<p>
									And that's not all!{' '}
									<i style={{ color: '#310033', textDecoration: 'underline' }}>
										Act now and receive 7 additional life-changing reports absolutely FREE as exclusive bonuses, valued at a total of ₹9,979/-.
									</i>
								</p>

								<p>
									Take the first step towards your abundant future by ordering{' '}
									<span style={{ color: '#310033', fontWeight: 'bold' }}>
										"Your Abundance Blueprint"
									</span>{' '}
									today. Let the power of Vastu Shastra and Astrology guide you to a life of fulfilment and success.
								</p>

								<p>
									Wishing you an abundant and blessed life ahead!
								</p>

							</Typography>

							<Box
								display="flex" justifyContent="left"
							>
								<Box

									component="img"
									src={`${process.env.PUBLIC_URL}/Images/review8.png`}
									alt="Life-Changing Bonuses"
									sx={{
										width: {
											xs: '40%',
											sm: '25%',
											md: '25%'
										}, height: 'auto',
										marginTop: '20px',
									}}
								/>
							</Box>
							<Typography
								variant="body1"
								color="black"
								textAlign="left"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem',
										md: '1.2rem'
									}
								}}             >

								<p>
									Warm regards,
									<br />
									Acharya Rajhkumar Singh
								</p>

								<p>
									<span style={{ color: '#2c3e50', fontWeight: 'bold' }}>
										P.S.
									</span>{' '}
									Still on the fence? Here's what sets
									<span style={{ color: '#2c3e50', fontWeight: 'bold' }}>
										"Your Abundance Blueprint Report"
									</span>{' '}
									apart:
								</p>

								<p>
									<span style={{ color: '#2c3e50', fontWeight: 'bold' }}>
										Personalized Guidance:
									</span>{' '}
									No generic solutions. Discover the secrets tailored to YOUR birth chart and life goals.
								</p>

								<p>
									<span style={{ color: '#2c3e50', fontWeight: 'bold' }}>
										Actionable Steps:
									</span>{' '}
									Get a clear roadmap to attract abundance and success, not just vague predictions.
								</p>

								<p>
									<span style={{ color: '#2c3e50', fontWeight: 'bold' }}>
										Limited Time Bonus:
									</span>{' '}
									The FREE valuable bonuses like Rudraksha recommendation and auspicious seating direction (total value ₹18,000/-) are only available for a limited time.
								</p>

								<p>
									Don't wait for this opportunity to disappear. Order
									<span style={{ color: '#2c3e50', fontWeight: 'bold' }}>
										"Your Abundance Blueprint Report"
									</span>{' '}
									now and unlock your full potential!
								</p>

								<Link
									to={linkToNewUserPurchaseComponent}
									rel="noopener noreferrer"
									style={{
										color: '#178bf6', textDecoration: 'none',
									}}
									onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
									onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
								>
									👉 Order Your Report Now
								</Link>

							</Typography>

						</Grid>
					</Grid>
				</Container>
			</Box>

			<Box
				sx={{
					width: '100%',
					backgroundColor: 'black',
					display: 'flex',
					flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', py: '50px',
				}}
			>
				<Container sx={{ maxWidth: { xs: '90%', sm: '35%', md: '35%' } }}>
					<Grid container alignItems="flex-start" justifyContent="center" spacing={2}>
						<Grid size={12}>

							<Box
								component="img"
								src={`${process.env.PUBLIC_URL}/Images/landingPage1.png`}
								alt="Life-Changing Bonuses"
								sx={{
									width: '100%', height: 'auto',
								}}
							/>

							<Box
								display="flex" justifyContent="center"
							>
								<Box

									component="img"
									src={`${process.env.PUBLIC_URL}/Images/down2.png`}
									alt="Life-Changing Bonuses"
									sx={{
										width: '25%', height: 'auto',
										marginTop: '10px',
									}}
								/>
							</Box>

							<Link to={linkToNewUserPurchaseComponent}>
								<Button
									variant="contained"
									sx={{
										width: '100%',
										p: '10px',
										m: '10px',
										fontSize: {
											xs: '1rem',
											sm: '1.2rem',
											md: '1.2rem'
										},
										fontWeight: 'bold',
										backgroundColor: '#f8d371',
										color: 'black',
										borderRadius: '50px',
										border: '3px solid white',
										textTransform: 'none',
										'&:hover': {
											backgroundColor: '#d4ac0d',
										},
									}}
								>
									Order Your Report Now!
								</Button>
							</Link>

							<Typography
								variant="body1"
								color="white"
								textAlign="center"
								sx={{ fontSize: '1.1rem', marginTop: '30px' }}             >
								<p>
									<span style={{ fontWeight: 'bold' }}>
										SPACE INFINITE
									</span>
									<br />
									Tiara C-403, Lodha Upper Thane
									<br />
									Anjur - Mankoli Raod, Thane 421302
									<br />
									📲 +91 7888744481
									<br />
									📧 vastu@rajhkumarsingh.com
								</p>

								{/* Footer Links */}
								<p style={{ marginTop: '50px', fontSize: '0.9rem' }}>
									<Link to="/Refund" style={{ color: 'white', textDecoration: 'none' }}
										onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
										onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
									>
										&nbsp;Refund&nbsp;</Link>|
									<Link to="/Terms" style={{ color: 'white', textDecoration: 'none' }}
										onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
										onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
									>
										&nbsp;Terms&nbsp;</Link>|
									<Link to="/Privacy" style={{ color: 'white', textDecoration: 'none' }}
										onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
										onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
									>
										&nbsp;Privacy Policy&nbsp;</Link>|
									<Link to="/Shipping" style={{ color: 'white', textDecoration: 'none' }}
										onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
										onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}>
										&nbsp;Shipping&nbsp;</Link>
								</p>

								<p style={{ marginTop: '50px', fontSize: '0.9rem' }}>
									© Space Infinite | All Rights Reserved
								</p>
							</Typography>
						</Grid>
					</Grid>
				</Container>

				<Container sx={{ maxWidth: { xs: '100%', sm: '50%', md: '50%', borderTop: '2px dashed white', marginTop: '50px' } }}>
					<Grid container alignItems="flex-start" justifyContent="center" spacing={2}>
						<Grid size={12}>

							<Typography
								variant="body1"
								color="white"
								textAlign="left"
								sx={{ fontSize: '0.9rem', marginTop: '20px' }}             >
								<p>
									<span style={{ fontWeight: 'bold' }}>
										Meta Policy:
									</span>{' '}
									This website is not a part of the Facebook or Instagram website or Meta Platforms, Inc. / Meta. Additionally, this site is NOT endorsed by Facebook or Instagram in ANY WAY.
								</p>
								<p>
									FACEBOOK and INSTAGRAM are trademarks of Meta Platforms Inc.
								</p>
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</Box>


			{/* Load Google Fonts */}
			<link rel="preconnect" href="https://fonts.googleapis.com" />
			<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
			<link
				href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Alatsi&display=swap"
				rel="stylesheet"
			/>
		</ThemeProvider >
	);
};