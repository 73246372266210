import "react-international-phone/style.css";
import SearchIcon from "@mui/icons-material/Search";

import {
	BaseTextFieldProps,
	InputAdornment,
	ListSubheader,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import {
	defaultCountries,
	FlagImage,
	parseCountry,
	usePhoneInput,
} from "react-international-phone";

interface MuiPhoneProps extends BaseTextFieldProps {
	value: string;
	defaultCountry: string;
	onChange: Function;
}

export const MuiPhone = ({
	value,
	defaultCountry,
	onChange,
	...restProps
}: MuiPhoneProps) => {
	const [searchText, setSearchText] = useState("");
	const {
		inputValue,
		handlePhoneValueChange,
		inputRef,
		country,
		setCountry,
	} = usePhoneInput({
		value,
		defaultCountry: defaultCountry,
		countries: defaultCountries,
		onChange: (data) => {
			onChange(data.phone);
		},
	});
	const containsText = (text: string, searchText: string) =>
		text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

	const filteredCountries = useMemo(
		() =>
			defaultCountries.filter((c) => {
				return containsText(c[0], searchText);
			}),
		[searchText]
	);

	return (
		<TextField
			variant="outlined"
			value={inputValue}
			onChange={handlePhoneValueChange}
			inputRef={inputRef}
			slotProps={{
				input: {
					startAdornment: (
						<InputAdornment
							position="start"
							style={{ marginRight: "2px", marginLeft: "-8px" }}
						>
							<Select
								MenuProps={{
									style: {
										height: "300px",
										width: "360px",
										top: "10px",
										left: "-34px",
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "left",
									},
								}}
								sx={{
									width: "max-content",
									// Remove default outline (display only on focus)
									fieldset: {
										display: "none",
									},
									'&.Mui-focused:has(div[aria-expanded="false"])':
									{
										fieldset: {
											display: "block",
										},
									},
									// Update default spacing
									".MuiSelect-select": {
										padding: "8px",
										paddingRight: "24px !important",
									},
									svg: {
										right: 0,
									},
								}}
								value={country.iso2}
								onChange={(e) => setCountry(e.target.value)}
								renderValue={(value) => (
									<FlagImage
										iso2={value}
										style={{ display: "flex" }}
									/>
								)}
							>
								{/* TextField is put into ListSubheader so that it doesn't
                				act as a selectable item in the menu
                				i.e. we can click the TextField without triggering any selection.*/}
								<ListSubheader>
									<TextField
										autoFocus
										fullWidth
										size="small"
										placeholder="Type to search..."
										slotProps={{
											input: {
												startAdornment: (
													<InputAdornment position="start">
														<SearchIcon />
													</InputAdornment>
												),
											},
										}}
										onChange={(e) =>
											setSearchText(e.target.value)
										}
										onKeyDown={(e) => {
											if (e.key !== "Escape") {
												// Prevents autoselecting item while typing (default Select behaviour)
												e.stopPropagation();
											}
										}}
									/>
								</ListSubheader>
								{filteredCountries.map((c) => {
									const country = parseCountry(c);
									return (
										<MenuItem
											key={country.iso2}
											value={country.iso2}
										>
											<FlagImage
												iso2={country.iso2}
												style={{ marginRight: "8px" }}
											/>
											<Typography marginRight="8px">
												{country.name}
											</Typography>
											<Typography color="gray">
												+{country.dialCode}
											</Typography>
										</MenuItem>
									);
								})}
							</Select>
						</InputAdornment>
					),
				},
			}}
			{...restProps}
		/>
	);
};
