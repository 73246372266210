import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Grid from "@mui/material/Grid2";
import { Card, CardContent, Stack } from "@mui/material";
import PurchasePage02 from "./PurchasePage02";
import PurchasePage03 from "./PurchasePage03";
import { Fragment, useState } from "react";
import { useParams } from 'react-router';

const steps = ["Step 1", "Step 2"];

export default function AddOnPackPurchase() {
	const { bdId } = useParams();

	const [globalData, setGlobalData] = useState({
		billId: -1,
		bdId: bdId,
		buyerGSTNumber: "",
		buyerGSTNumberIsValid: false,
		expediteDeliveryChecked: false,
		expediteDeliveryAmt: 0,
		ypbSelected: true,
		couponCodeIsValid: false,
		couponCodeAmt: 0,
		couponCode: "",
		gstPercent: 0,
		totalGstAmt: 0,
		taxableAmt: 0,
		payableAmt: 0,
	});
	const [skipped, setSkipped] = useState(new Set());
	const [activeStep, setActiveStep] = useState(0);

	const handleNext = () => {
		let newSkipped = skipped;
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	return (
		<Grid
			container
			spacing={2}
			alignItems="center"
			justifyContent="center"
			style={{ backgroundColor: "lightgray" }}
		>
			<Stack spacing={2} style={{ maxWidth: "700px" }}>
				<Card style={{ overflow: "visible", borderRadius: "7px" }}>
					<CardContent>
						<Stepper
							activeStep={activeStep}
							style={{ paddingBottom: 16 }}
						>
							{steps.map((label, index) => {
								const stepProps = {};
								const labelProps = {};
								return (
									<Step key={label} {...stepProps}>
										<StepLabel {...labelProps}>
											{label}
										</StepLabel>
									</Step>
								);
							})}
						</Stepper>
						<Fragment>
							{activeStep === 0 && (
								<PurchasePage02
									globalData={globalData}
									setGlobalData={setGlobalData}
									handleBack={handleBack}
									handleNext={handleNext}
								/>
							)}
							{activeStep === 1 && (
								<PurchasePage03
									globalData={globalData}
									setGlobalData={setGlobalData}
									handleBack={handleBack}
									handleNext={handleNext}
								/>
							)}
						</Fragment>
					</CardContent>
				</Card>
			</Stack>
		</Grid>
	);
}
