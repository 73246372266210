import PBPageImage from "../../images/YPR FINAL - 16x9 - 09 Sep 2024.gif";
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	CardMedia,
	Grid2 as Grid,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from "@mui/material";
import { Done } from "@mui/icons-material";
import { useState, useEffect } from "react";
import { prefixBaseURL, consoleLog, consoleError } from "../../Utils";

interface PackDetail {
	pack_id: string;
	pack_name_en: string;
	pack_mrp: number;
	offer_price1: number;
	offer_price2: number;
}

interface PurchasePage02Props {
	globalData: any;
	setGlobalData: Function;
	handleBack: Function;
	handleNext: Function;
}

export default function PurchasePage02({
	globalData,
	setGlobalData,
	handleBack,
	handleNext,
}: PurchasePage02Props) {
	const [packDetail, setPackDetail] = useState<PackDetail>();
	useEffect(() => {
		fetch(prefixBaseURL("/v1/purchase/pack_name"), {
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		}).then(function (response) {
			return response.json();
		}).then(function (result) {
			const foundPackDetail = result.find((pd: PackDetail) => pd.pack_id.toUpperCase() === "ADDON01_240805");
			if (foundPackDetail) {
				setPackDetail(foundPackDetail);
			}
		}).catch(function (error) {
			consoleError(error);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Grid container alignItems="center" justifyContent="center">
			<Stack spacing={2} style={{ maxWidth: "700px" }}>
				<Card style={{ overflow: "visible", borderRadius: "7px" }}>
					<CardHeader
						title="Your Prediction Blueprint"
						titleTypographyProps={{ variant: "h6" }}
						style={{ backgroundColor: "orange", borderRadius: "7px" }}
					/>
					<CardMedia
						component="img"
						image={PBPageImage}
						sx={{
							objectFit: "contain",
							backgroundColor: "purple",
						}}
					/>
					<CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
						<TableContainer component={Paper}>
							<Table size="small">
								<TableBody>
									<TableRow>
										<TableCell>
											<Typography>
												Actual Price:
											</Typography>
										</TableCell>
										<TableCell align="right">
											<Typography noWrap>
												<del>{packDetail?.pack_mrp}</del> ₹
											</Typography>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<Typography variant="h6">
												<b>Discounted Price:</b>
											</Typography>
										</TableCell>
										<TableCell align="right">
											<Typography variant="h6" noWrap>
												{packDetail?.offer_price1} ₹
											</Typography>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</CardContent>
				</Card>
				<Card style={{ overflow: "visible", borderRadius: "7px" }}>
					<CardHeader
						title="Benefits of this Report"
						titleTypographyProps={{ variant: "h6" }}
						style={{ backgroundColor: "orange", borderRadius: "7px" }}
					/>
					<CardContent>
						<Grid container spacing={2}>
							{[
								"Know your Lakshmi Point",
								"Know your Abundance Point",
								"Prediction: Nakshatra",
								"Prediction: Varshphal",
								"Prediction: Transit",
								"Prediction: Rashi in Various Houses",
								"Prediction: Planet in Rashi",
								"Prediction: Planet Combination",
								"Remedies: Varshphal",
								"Remedies: Nakshatra",
								"Remedies: Transit",
								"Strength and Weakness",
							].map((item, index) => {
								return (
									<Grid key={index} size={{ xs: 12, md: 6 }}>
										<Stack spacing={1} direction={"row"}>
											<Done color="success" />
											<Typography>{item}</Typography>
										</Stack>
									</Grid>
								);
							})}
						</Grid>
					</CardContent>
				</Card>
				<Box sx={{ display: "flex", flexDirection: "row" }}>
					<Box sx={{ flex: "1 1 auto" }} />
					<Button
						variant="contained"
						onClick={() => {
							setGlobalData((prevState: any) => ({
								...prevState,
								ypbSelected: true,
							}));
							handleNext();
						}}
					>
						Next
					</Button>
				</Box>
			</Stack>
		</Grid>
	);
}
