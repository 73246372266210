export interface Place {
	place: string;
	tzone: string;
	lat: number;
	lon: number;
}

export function consoleLog(message: any, ...optionalParams: any[]) {
	if (window.location.host.includes("localhost")) {
		console.log(message, optionalParams);
	}
}

export function consoleError(message: any, ...optionalParams: any[]) {
	if (window.location.host.includes("localhost")) {
		console.error(message, optionalParams);
	}
}

export function prefixBaseURL(suffix: string) {
	if (window.location.host.includes("3000")) {
		return (
			"//" + window.location.host.replace("3000", "8080") + "/ab" + suffix
		);
	} else if (!window.location.host.includes("yourabundanceblueprint.info")) {
		return "/ab" + suffix;
	} else {
		return suffix;
	}
}

function parseDateString(dateString: string) {
	const dateOnlyRegex =
		/^([0-9]([0-9]([0-9][1-9]|[1-9]0)|[1-9]00)|[1-9]000)(-(0[1-9]|1[0-2])(-(0[1-9]|[1-2][0-9]|3[0-1])))$/;
	if (dateOnlyRegex.test(dateString)) {
		const utcDate = new Date(dateString);
		const localDate = new Date(
			utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
		);
		return localDate;
	}
	return new Date(dateString);
}

// Validates that the input string is a valid date formatted as "mm/dd/yyyy"
function isValidDate(dateString: string) {
	// First check for the pattern
	if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;

	// Parse the date parts to integers
	var parts = dateString.split("/");
	var day = parseInt(parts[1], 10);
	var month = parseInt(parts[0], 10);
	var year = parseInt(parts[2], 10);

	// Check the ranges of month and year
	if (year < 1000 || year > 3000 || month === 0 || month > 12) return false;

	var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

	// Adjust for leap years
	if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
		monthLength[1] = 29;

	// Check the range of the day
	return day > 0 && day <= monthLength[month - 1];
}
