import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Grid from "@mui/material/Grid2";
import { Card, CardContent, Stack } from "@mui/material";
import PurchasePage01 from "./PurchasePage01";
import PurchasePage02 from "./PurchasePage02";
import PurchasePage03 from "./PurchasePage03";
import { Fragment, useState } from "react";
import PurchasePage04 from "./PurchasePage04";

const steps = ["Step 1", "Step 2", "Step 3"];

export default function NewUserPurchase() {
	const [globalData, setGlobalData] = useState({
		billId: -1,
		bdId: -1,
		mobile: "",
		email: "",
		gender: "",
		lnKey: "",
		firstName: "",
		lastName: "",
		reportFor: "",
		day: "",
		month: "",
		year: "",
		hour: "",
		min: "",
		country: "",
		place: "",
		lat: 0,
		lon: 0,
		tzone: "",
		buyerGSTNumber: "",
		buyerGSTNumberIsValid: false,
		expediteDeliveryChecked: false,
		expediteDeliveryAmt: 0,
		ypbSelected: false,
		couponCodeIsValid: false,
		couponCodeAmt: 0,
		couponCode: "",
		gstPercent: 0,
		totalGstAmt: 0,
		taxableAmt: 0,
		payableAmt: 0,
		packIdsCsv: ""
	});
	const [skipped, setSkipped] = useState(new Set());
	const [activeStep, setActiveStep] = useState(0);

	const handleNext = () => {
		let newSkipped = skipped;
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	return (
		<Grid
			container
			spacing={2}
			alignItems="center"
			justifyContent="center"
			style={{ backgroundColor: "lightgray" }}
		>
			<Stack spacing={2} style={{ maxWidth: "700px" }}>
				<Card style={{ overflow: "visible", borderRadius: "7px" }}>
					<CardContent>
						<Stepper
							activeStep={activeStep}
							style={{ paddingBottom: 16 }}
						>
							{steps.map((label, index) => {
								const stepProps = {};
								const labelProps = {};
								return (
									<Step key={label} {...stepProps}>
										<StepLabel {...labelProps}>
											{label}
										</StepLabel>
									</Step>
								);
							})}
						</Stepper>
						{activeStep === steps.length ? (
							<Fragment>
								<PurchasePage04
									globalData={globalData}
									setGlobalData={setGlobalData}
									handleBack={handleReset}
									handleNext={handleNext}
								/>
							</Fragment>
						) : (
							<Fragment>
								{activeStep === 0 && (
									<PurchasePage01
										globalData={globalData}
										setGlobalData={setGlobalData}
										handleNext={handleNext}
									/>
								)}
								{activeStep === 1 && (
									<PurchasePage02
										globalData={globalData}
										setGlobalData={setGlobalData}
										handleBack={handleBack}
										handleNext={handleNext}
									/>
								)}
								{activeStep === 2 && (
									<PurchasePage03
										globalData={globalData}
										setGlobalData={setGlobalData}
										handleBack={handleBack}
										handleNext={handleNext}
									/>
								)}
								{/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
									<Button
										color="inherit"
										disabled={activeStep === 0}
										onClick={handleBack}
										sx={{ mr: 1 }}
									>
										Back
									</Button>
									<Box sx={{ flex: '1 1 auto' }} />
									<Button onClick={handleNext}>
										{activeStep === steps.length - 1 ? 'Finish' : 'Next'}
									</Button>
								</Box> */}
							</Fragment>
						)}
					</CardContent>
				</Card>
			</Stack>
		</Grid>
	);
}
